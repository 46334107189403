import { gql } from '@apollo/client'

export const payslipsTabPayslipsQuery = gql`
  query PayslipsTabPayslipsQuery(
    $employeeId: ID!
    $wageStart: Date
    $wageEnd: Date
    $calculationStart: Date
    $calculationEnd: Date
  ) {
    payslips(
      employeeId: $employeeId
      wageStart: $wageStart
      wageEnd: $wageEnd
      calculationStart: $calculationStart
      calculationEnd: $calculationEnd
    ) {
      creationDate
      key
      pdfUrl
      wageDate
    }
  }
`

import { useDateFormat } from '@vivaldis/common'
import { FC, useMemo } from 'react'
import { PayslipsTabPayslipsQuery_payslips as Payslip } from '../../../graphql/__generated__/PayslipsTabPayslipsQuery'

interface Props {
  payslip: Payslip
}

export const WageDate: FC<Props> = ({ payslip }) => {
  const formatDate = useDateFormat('dd/MM/yyyy')

  const text = useMemo(() => {
    if (payslip.wageDate) {
      return formatDate(new Date(payslip.wageDate))
    }
    return null
  }, [formatDate, payslip.wageDate])

  return <>{text}</>
}

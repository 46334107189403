import { Table, InfiniteTable } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PayslipsTabPayslipsQuery_payslips as Payslip } from '../../graphql/__generated__/PayslipsTabPayslipsQuery'
import {
  usePayslips,
  usePayslipsTabPayslips
} from '../../providers/PayslipsTabPayslipsQueryProvider'
import { CreationDate } from './components/CreationDate'
import { Key } from './components/Key'
import { WageDate } from './components/WageDate'
import { Actions } from './components/Actions'

export const PayslipsList: FC = () => {
  const [t] = useTranslation()

  const { loading: isLoading, error } = usePayslipsTabPayslips()

  const payslips = usePayslips()

  const showLoading = useMemo(
    () => isLoading && !payslips.length,
    [payslips.length, isLoading]
  )

  return (
    <InfiniteTable<Payslip>
      dataSource={payslips}
      rowKey="key"
      loading={showLoading}
      isLoadingMore={false}
      onLoadMore={() => {}}
      hasNextPage={false}
      error={error}
    >
      <Table.Column<Payslip>
        title={t(
          'screens.employee_details.tabs.payslips.table.columns.key.title'
        )}
        key="key"
        render={(value, payslip) => <Key payslip={payslip} />}
      />
      <Table.Column<Payslip>
        title={t(
          'screens.employee_details.tabs.payslips.table.columns.creation_date.title'
        )}
        key="creationDate"
        render={(value, payslip) => <CreationDate payslip={payslip} />}
      />
      <Table.Column<Payslip>
        title={t(
          'screens.employee_details.tabs.payslips.table.columns.wage_date.title'
        )}
        key="wageDate"
        render={(value, payslip) => <WageDate payslip={payslip} />}
      />
      <Table.Column<Payslip>
        title={t(
          'screens.employee_details.tabs.payslips.table.columns.actions.title'
        )}
        key="actions"
        align="right"
        width={180}
        render={(value, payslip) => <Actions payslip={payslip} />}
      />
    </InfiniteTable>
  )
}

import { PaginatedQueryResult, usePaginatedQuery } from '@vivaldis/graphql'
import {
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  useMemo
} from 'react'
import {
  SuEmployeesSidebarQuery,
  SuEmployeesSidebarQuery_suEmployees_edges_node,
  SuEmployeesSidebarQueryVariables
} from '../graphql/__generated__/SuEmployeesSidebarQuery'
import { suEmployeesSidebarQuery } from '../graphql/suEmployeesSidebarQuery'
import { useForm } from './FormProvider'

export interface SuEmployeesQueryContextValue
  extends PaginatedQueryResult<
    SuEmployeesSidebarQuery,
    SuEmployeesSidebarQueryVariables
  > {}

export const QueryContext = createContext<
  SuEmployeesQueryContextValue | undefined
>(undefined)

export function useSuEmployeesQuery(): SuEmployeesQueryContextValue {
  return useContext(QueryContext)!
}

export function useSuEmployees(): SuEmployeesSidebarQuery_suEmployees_edges_node[] {
  const { data } = useSuEmployeesQuery()

  return useMemo(() => {
    if (data?.suEmployees?.edges) {
      return data.suEmployees.edges
        .map(edge => edge && edge.node)
        .filter(
          node => node !== null
        ) as SuEmployeesSidebarQuery_suEmployees_edges_node[]
    }
    return []
  }, [data?.suEmployees.edges])
}

export const SuEmployeesProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const { values } = useForm()
  const variables = useMemo(() => {
    return {
      name: values.name,
      phone: values.phone
    }
  }, [values.name, values.phone])

  const queryResult = usePaginatedQuery<
    'suEmployees',
    SuEmployeesSidebarQuery,
    SuEmployeesSidebarQueryVariables
  >(suEmployeesSidebarQuery, {
    property: 'suEmployees',
    variables
  })
  return (
    <QueryContext.Provider value={queryResult}>
      {children}
    </QueryContext.Provider>
  )
}

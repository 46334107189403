import { Form, Input } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SignInResult } from '@vivaldis/session'

interface Props {
  signIn: SignInResult
}

export const FormContent: FC<Props> = ({ signIn }) => {
  const [t] = useTranslation()

  const showOTPCodeInput = useMemo(() => {
    return (
      signIn.data &&
      'otp_verification_required' in signIn.data &&
      !!signIn.data?.otp_verification_required &&
      !!signIn.data?.otp_verification_token
    )
  }, [signIn.data])

  return (
    <>
      <Form.Item
        label={t('screens.login.form.email.label')}
        name="email"
        required
      >
        <Input
          name="email"
          placeholder={t('screens.login.form.email.placeholder')}
          type="email"
          autoComplete="username"
          disabled={showOTPCodeInput}
        />
      </Form.Item>
      <Form.Item
        label={t('screens.login.form.password.label')}
        name="password"
        required
      >
        <Input.Password
          name="password"
          placeholder={t('screens.login.form.password.placeholder')}
          autoComplete="current-password"
          disabled={showOTPCodeInput}
        />
      </Form.Item>
      {showOTPCodeInput ? (
        <Form.Item
          label={t('screens.login.form.otp_verification_code.label')}
          name="otp_verification_code"
          required
        >
          <Input
            name="otp_verification_code"
            placeholder={t(
              'screens.login.form.otp_verification_code.placeholder'
            )}
            autoFocus={true}
            autoComplete="one-time-code"
          />
        </Form.Item>
      ) : null}
    </>
  )
}

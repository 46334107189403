import { UserNavigationBar } from '@vivaldis/antd-ui'
import { Home, Paper, Search, TreeUser } from '@vivaldis/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarAndMenu } from './components/AvatarAndMenu'

export const NavigationBar: FC = () => {
  const { t } = useTranslation('components')

  return (
    <UserNavigationBar
      navigationItems={
        <>
          <UserNavigationBar.Item
            to="companies"
            label={t('navigation_bar.companies')}
            id="cy-nav-companies"
            icon={<Home style={{ fontSize: 20 }} />}
          />
          <UserNavigationBar.Item
            to="employees"
            label={t('navigation_bar.employees')}
            id="cy-nav-employees"
            icon={<Search style={{ fontSize: 20 }} />}
          />
          <UserNavigationBar.Item
            to="contracts"
            label={t('navigation_bar.contracts')}
            id="cy-nav-4d"
            icon={<Paper style={{ fontSize: 20 }} />}
          />
          <UserNavigationBar.Item
            to="users"
            label={t('navigation_bar.users')}
            id="cy-nav-users"
            icon={<TreeUser style={{ fontSize: 20 }} />}
          />
        </>
      }
    >
      <AvatarAndMenu />
    </UserNavigationBar>
  )
}

import {
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  useMemo
} from 'react'
import { EmployeesSidebarEmployeeFragment } from '../graphql/__generated__/EmployeesSidebarEmployeeFragment'
import { SearchType, useForm } from './FormProvider'
import {
  SuEmployeesQueryContextValue,
  useSuEmployees,
  useSuEmployeesQuery
} from './SuEmployeesProvider'
import {
  SuEmployeesWithMissingSafetyAnswersQueryContextValue,
  useSuEmployeesWithMissingSafetyAnswers,
  useSuEmployeesWithMissingSafetyAnswersQuery
} from './SuEmployeesWithMissingSafetyAnswersQueryProvider'
import {
  SuEmployeesWithMissingCertificatesQueryContextValue,
  useSuEmployeesWithMissingCertificates,
  useSuEmployeesWithMissingCertificatesQuery
} from './SuEmployeesWithMissingCertificatesQueryProvider'

export type QueryContextValue = {
  isLoadingMore: boolean
  hasNextPage: boolean
  loadMore: SuEmployeesQueryContextValue['loadMore']
} & (
  | SuEmployeesWithMissingCertificatesQueryContextValue
  | SuEmployeesQueryContextValue
  | SuEmployeesWithMissingSafetyAnswersQueryContextValue
)

export const QueryContext = createContext<QueryContextValue | undefined>(
  undefined
)

export function useQuery(): QueryContextValue {
  return useContext(QueryContext)!
}

export function useEmployees(): EmployeesSidebarEmployeeFragment[] {
  const { values } = useForm()

  const suEmployees = useSuEmployees()
  const suEmployeesWithMissingCertificates =
    useSuEmployeesWithMissingCertificates()
  const suEmployeesWithMissingAnswers = useSuEmployeesWithMissingSafetyAnswers()

  return useMemo(() => {
    switch (values.searchType) {
      case SearchType.ALL_EMPLOYEES:
        return suEmployees
      case SearchType.MISSING_CERTIFICATES:
        return suEmployeesWithMissingCertificates
      case SearchType.MISSING_SAFETY_ANSWERS:
        return suEmployeesWithMissingAnswers
      default:
        return []
    }
  }, [
    suEmployees,
    suEmployeesWithMissingAnswers,
    suEmployeesWithMissingCertificates,
    values.searchType
  ])
}

export const QueryProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { values } = useForm()

  const suEmployeesQuery = useSuEmployeesQuery()
  const suEmployeesWithMissingCertificatesQuery =
    useSuEmployeesWithMissingCertificatesQuery()
  const suEmployeesWithMissingSafetyAnswersQuery =
    useSuEmployeesWithMissingSafetyAnswersQuery()

  const context = useMemo(() => {
    if (values.searchType === SearchType.MISSING_CERTIFICATES) {
      return {
        isLoadingMore: false,
        hasNextPage: false,
        loadMore: async () => undefined,
        ...suEmployeesWithMissingCertificatesQuery
      }
    }

    if (values.searchType === SearchType.ALL_EMPLOYEES) {
      return suEmployeesQuery
    }

    if (values.searchType === SearchType.MISSING_SAFETY_ANSWERS) {
      return {
        isLoadingMore: false,
        hasNextPage: false,
        loadMore: async () => undefined,
        ...suEmployeesWithMissingSafetyAnswersQuery
      }
    }

    return suEmployeesQuery
  }, [
    suEmployeesQuery,
    suEmployeesWithMissingSafetyAnswersQuery,
    suEmployeesWithMissingCertificatesQuery,
    values.searchType
  ])

  return (
    <QueryContext.Provider value={context}>{children}</QueryContext.Provider>
  )
}

import { FC } from 'react'
import { QueryProvider } from './providers/QueryProvider'
import { Screen } from './screen'

const RegisterOTP: FC = () => {
  return (
    <QueryProvider>
      <Screen />
    </QueryProvider>
  )
}

export default RegisterOTP

import { Alert, Button } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Header } from '../../components/Header'
import { ScreenBase } from '../../components/ScreenBase'
import { ScreenContent } from '../components/ScreenContent'
import { useQuery } from '../providers/QueryProvider'

export const Screen: FC = () => {
  const [t] = useTranslation()

  const { error } = useQuery()

  if (error) {
    return (
      <ScreenBase title={t('screens.register_otp.title')}>
        <Header>{t('screens.register_otp.title')}</Header>
        <Alert
          message={error}
          type="error"
          action={
            <Button type="link" to="/login">
              {t('screens.register_otp.try_again_button')}
            </Button>
          }
        />
      </ScreenBase>
    )
  }

  return (
    <ScreenBase title={t('screens.register_otp.title')}>
      <Header>{t('screens.register_otp.title')}</Header>
      <ScreenContent />
    </ScreenBase>
  )
}

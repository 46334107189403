import { QueryResult } from '@apollo/client'
import { useQuery as useQueryBase } from '@apollo/client/react/hooks/useQuery'
import {
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  useMemo
} from 'react'
import {
  SuEmployeesWithMissingSafetyAnswersSidebarQuery,
  SuEmployeesWithMissingSafetyAnswersSidebarQuery_suEmployeesWithMissingSafetyAnswers
} from '../graphql/__generated__/SuEmployeesWithMissingSafetyAnswersSidebarQuery'
import { suEmployeesWithMissingSafetyAnswersSidebarQuery } from '../graphql/suEmployeesWithMissingSafetyAnswersSidebarQuery'

export interface SuEmployeesWithMissingSafetyAnswersQueryContextValue
  extends QueryResult<SuEmployeesWithMissingSafetyAnswersSidebarQuery> {}

export const QueryContext = createContext<
  SuEmployeesWithMissingSafetyAnswersQueryContextValue | undefined
>(undefined)

export function useSuEmployeesWithMissingSafetyAnswersQuery(): SuEmployeesWithMissingSafetyAnswersQueryContextValue {
  return useContext(QueryContext)!
}

export function useSuEmployeesWithMissingSafetyAnswers(): SuEmployeesWithMissingSafetyAnswersSidebarQuery_suEmployeesWithMissingSafetyAnswers[] {
  const { data } = useSuEmployeesWithMissingSafetyAnswersQuery()
  return useMemo(() => {
    return data?.suEmployeesWithMissingSafetyAnswers || []
  }, [data?.suEmployeesWithMissingSafetyAnswers])
}

export const SuEmployeesWithMissingSafetyAnswersQueryProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const queryResult =
    useQueryBase<SuEmployeesWithMissingSafetyAnswersSidebarQuery>(
      suEmployeesWithMissingSafetyAnswersSidebarQuery
    )

  return (
    <QueryContext.Provider value={queryResult}>
      {children}
    </QueryContext.Provider>
  )
}

import { gql } from '@apollo/client'
import { employeesSidebarEmployeeFragment } from './employeesSidebarEmployeeFragment'

export const suEmployeesWithMissingCertificatesSidebarQuery = gql`
  ${employeesSidebarEmployeeFragment}

  query SuEmployeesWithMissingCertificatesSidebarQuery(
    $periodStart: Date!
    $periodEnd: Date!
  ) {
    suEmployeesWithMissingCertificates(
      periodStart: $periodStart
      periodEnd: $periodEnd
    ) {
      ...EmployeesSidebarEmployeeFragment
    }
  }
`

import { getApolloClient } from '@vivaldis/common'
import {
  DeleteEmployeeCertificateInput,
  deleteEmployeeCertificateMutation
} from '@vivaldis/graphql'
import { employeeDetailsQuery } from './employeeDetailsQuery'

export const suRejectCertificate = (input: DeleteEmployeeCertificateInput) => {
  const apolloClient = getApolloClient()
  return deleteEmployeeCertificateMutation(apolloClient, {
    refetchQueries: [employeeDetailsQuery],
    variables: {
      input: input
    }
  })
}

import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const MoreCircleSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 12C3.5 7.313 7.313 3.5 12 3.5C16.687 3.5 20.5 7.313 20.5 12C20.5 16.687 16.687 20.5 12 20.5C7.313 20.5 3.5 16.687 3.5 12ZM2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2C6.486 2 2 6.486 2 12ZM14.9432 12.0137C14.9432 12.5667 15.3952 13.0137 15.9482 13.0137C16.5012 13.0137 16.9482 12.5667 16.9482 12.0137C16.9482 11.4607 16.5012 11.0137 15.9482 11.0137H15.9382C15.3862 11.0137 14.9432 11.4607 14.9432 12.0137ZM11.9384 13.0137C11.3854 13.0137 10.9344 12.5667 10.9344 12.0137C10.9344 11.4607 11.3764 11.0137 11.9294 11.0137H11.9384C12.4914 11.0137 12.9384 11.4607 12.9384 12.0137C12.9384 12.5667 12.4914 13.0137 11.9384 13.0137ZM6.92468 12.0137C6.92468 12.5667 7.37668 13.0137 7.92968 13.0137C8.48268 13.0137 8.92968 12.5667 8.92968 12.0137C8.92968 11.4607 8.48268 11.0137 7.92968 11.0137H7.92068C7.36768 11.0137 6.92468 11.4607 6.92468 12.0137Z"
    />
  </svg>
)

export const MoreCircle = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={MoreCircleSvg} {...props} ref={ref} />
)

import { FC } from 'react'
import { GoBackToProvider } from '@vivaldis/web-ui'
import { ProgressBarProvider } from './providers/ProgressBarProvider'
import { ActionsProvider } from './providers/ActionsProvider'
import { QueryProvider } from './providers/QueryProvider'
import { EmployeeDetailsScreen } from './screen'

const EmployeeDetails: FC = () => {
  return (
    <GoBackToProvider value="/employees">
      <QueryProvider>
        <ActionsProvider>
          <ProgressBarProvider>
            <EmployeeDetailsScreen />
          </ProgressBarProvider>
        </ActionsProvider>
      </QueryProvider>
    </GoBackToProvider>
  )
}

export default EmployeeDetails

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'
import { VerifyOTPRequestData } from '../api/verifyOTP'
import { isSessionReadySelector, verifyOTPSelector } from '../selectors'
import { SessionVerifyOTP } from '../reducer'
import { verifyOTP } from '../actions'

export interface VerifyOTPResult extends SessionVerifyOTP {
  verifyOTP: (
    data: VerifyOTPRequestData,
    onSuccessSignIn?: () => void,
    onFailedSignIn?: (error: unknown) => void
  ) => void
}

export function useVerifyOTP(): VerifyOTPResult {
  const dispatch = useDispatch()
  const verifyOTPState = useSelector(verifyOTPSelector)
  const isReady = useSelector(isSessionReadySelector)

  const verifyOTPCallback = useCallback(
    (
      data: VerifyOTPRequestData,
      onSuccessVerifyOTP?: () => void,
      onFailedVerifyOTP?: (error: unknown) => void
    ) => {
      dispatch(verifyOTP(data, { onSuccessVerifyOTP, onFailedVerifyOTP }))
    },
    [dispatch]
  )

  const result = useMemo(
    (): VerifyOTPResult => ({
      ...verifyOTPState,
      verifyOTP: verifyOTPCallback,
      data: isReady ? verifyOTPState.data : undefined
    }),
    [isReady, verifyOTPCallback, verifyOTPState]
  )

  return result
}

import { getI18n } from 'react-i18next'

// when we have a custom error message that is not translated (means that we don't expect it), we show original error message for easier debugging
export const getErrorDescriptionForUnhandledError = (error: unknown) => {
  if (error instanceof Error) {
    // see packages/common/src/BackendErrorMessages/tBackendErrorMessage.ts:4
    const translationKeyForBackendMessage = `backend_error_messages.${error.message}`

    const isCustomErrorMessageExist = getI18n().exists(
      translationKeyForBackendMessage,
      {
        ns: 'common',
        nsSeparator: '☈' // We want to allow the use of the ":" symbol in translation keys, so we use rare UTF char instead to prevent conflicts
      }
    )

    if (isCustomErrorMessageExist) {
      return undefined
    }

    return error.message
  }
  return undefined
}

import { FC } from 'react'
import styled from '@emotion/styled'
import { PayslipsList } from './components/PayslipsList'
import { SearchForm } from './components/SearchForm'
import { PayslipsTabPayslipsQueryProvider } from './providers/PayslipsTabPayslipsQueryProvider'
import { PayslipsTabSearchFormProvider } from './providers/PayslipsTabSearchFormProvider'

export const PayslipsTab: FC = () => {
  return (
    <PayslipsTabSearchFormProvider>
      <PayslipsTabPayslipsQueryProvider>
        <Base>
          <SearchForm />
          <PayslipsList />
        </Base>
      </PayslipsTabPayslipsQueryProvider>
    </PayslipsTabSearchFormProvider>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 175px;
`

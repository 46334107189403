import { FC } from 'react'
import { EmployeeCertificateOrMissingCertificate } from '../index'

interface Props {
  certificate: EmployeeCertificateOrMissingCertificate
}

export const Position: FC<Props> = ({ certificate }) => {
  return <>{certificate.positionName}</>
}

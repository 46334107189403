import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const TickSquareSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 7.916C3.5 5.233 5.135 3.5 7.665 3.5H16.334C18.864 3.5 20.5 5.233 20.5 7.916V16.084C20.5 18.767 18.864 20.5 16.333 20.5H7.665C5.135 20.5 3.5 18.767 3.5 16.084V7.916ZM7.665 22H16.333C19.723 22 22 19.622 22 16.084V7.916C22 4.378 19.723 2 16.334 2H7.665C4.276 2 2 4.378 2 7.916V16.084C2 19.622 4.276 22 7.665 22ZM10.2834 14.9029C10.4294 15.0499 10.6224 15.1229 10.8134 15.1229C11.0054 15.1229 11.1974 15.0499 11.3434 14.9029L16.0894 10.1569C16.3824 9.86395 16.3824 9.38995 16.0894 9.09695C15.7964 8.80395 15.3224 8.80395 15.0294 9.09695L10.8134 13.3119L8.96945 11.4699C8.67645 11.1769 8.20245 11.1769 7.90945 11.4699C7.61645 11.7629 7.61645 12.2369 7.90945 12.5299L10.2834 14.9029Z"
    />
  </svg>
)

export const TickSquare = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={TickSquareSvg} {...props} ref={ref} />
)

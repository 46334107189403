import { FC } from 'react'
import styled from '@emotion/styled'
import { EmployeeDetailsQuery_employee } from '../../graphql/__generated__/EmployeeDetailsQuery'
import { CertificatesList } from './components/CertificatesList'

interface CompanyContentProps {
  employee: EmployeeDetailsQuery_employee
}

export const CertificatesTab: FC<CompanyContentProps> = ({ employee }) => {
  return (
    <Base>
      <CertificatesList companyId={String(employee.id)} />
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 175px;
`

import { App } from '@vivaldis/antd-ui'
import {
  createContext,
  useContext,
  useMemo,
  FC,
  PropsWithChildren,
  useCallback
} from 'react'
import { useTranslation } from 'react-i18next'
import { tracking } from '@vivaldis/tracking'
import { tBackendErrorMessage } from '@vivaldis/common'
import { getErrorDescriptionForUnhandledError } from '../../../../../utils/getErrorDescriptionForUnhandledError'
import { suMissingCertificateReminder } from '../graphql/suMissingCertificateReminderMutation'
import { suMissingSafetyAnswerReminderMutation } from '../graphql/suMissingSafetyAnswerReminderMutation'
import { suRejectCertificate } from '../graphql/suRejectCertificateMutation'
import { useParams } from '../hooks/useParams'

export interface ActionsContextValue {
  sendMissingCertificateReminder: (certificateType: string) => Promise<boolean>
  suMissingSafetyAnswerReminder: (safetyQuestion: string) => Promise<boolean>
  rejectCertificate: (certificateId: string) => Promise<boolean>
}

export const ActionsContext = createContext<ActionsContextValue | undefined>(
  undefined
)

export function useActions(): ActionsContextValue {
  return useContext(ActionsContext)!
}

export const ActionsProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const { notification } = App.useApp()
  const { t } = useTranslation()
  const { employeeId } = useParams()

  const handleSendMissingCertificateReminder = useCallback(
    async (certificateType: string) => {
      try {
        await suMissingCertificateReminder({ employeeId, certificateType })
        notification.success({
          message: t(
            'screens.employee_details.tabs.certificates.table.columns.actions.send_missing_certificate_reminder_confirmation_dialog.success_message'
          )
        })
        return true
      } catch (error) {
        tracking.error(error)

        notification.error({
          message: tBackendErrorMessage(error),
          description: getErrorDescriptionForUnhandledError(error)
        })
        return false
      }
    },
    [employeeId, notification, t]
  )

  const handleSuMissingSafetyAnswerReminder = useCallback(
    async (safetyQuestion: string) => {
      try {
        await suMissingSafetyAnswerReminderMutation({
          employeeId,
          safetyQuestion
        })
        notification.success({
          message: t(
            'screens.employee_details.tabs.unanswered_safety_questions.table.columns.actions.send_missing_safety_answer_reminder_confirmation_dialog.success_message'
          )
        })
        return true
      } catch (error) {
        tracking.error(error)

        notification.error({
          message: tBackendErrorMessage(error),
          description: getErrorDescriptionForUnhandledError(error)
        })
        return false
      }
    },
    [employeeId, notification, t]
  )

  const handleRejectCertificate = useCallback(
    async (certificateId: string) => {
      try {
        await suRejectCertificate({ id: certificateId })
        notification.success({
          message: t(
            'screens.employee_details.tabs.certificates.table.columns.actions.reject_certificate_confirmation_dialog.success_message'
          )
        })
        return true
      } catch (error) {
        tracking.error(error)

        notification.error({
          message: tBackendErrorMessage(error),
          description: getErrorDescriptionForUnhandledError(error)
        })
        return false
      }
    },
    [notification, t]
  )

  const context = useMemo(
    (): ActionsContextValue => ({
      sendMissingCertificateReminder: handleSendMissingCertificateReminder,
      suMissingSafetyAnswerReminder: handleSuMissingSafetyAnswerReminder,
      rejectCertificate: handleRejectCertificate
    }),
    [
      handleRejectCertificate,
      handleSendMissingCertificateReminder,
      handleSuMissingSafetyAnswerReminder
    ]
  )

  return (
    <ActionsContext.Provider value={context}>
      {children}
    </ActionsContext.Provider>
  )
}

interface Workday {
  startsAt: string | any
}

export function sortWorkdaysByStartsAt<T extends Workday>(workdays: T[]): T[] {
  const final = [...workdays]
  final.sort((a, b) => {
    if (a.startsAt < b.startsAt) return -1
    return 1
  })
  return final
}

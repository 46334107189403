import styled from '@emotion/styled'
import {
  Badge,
  InfiniteList,
  TextStylePreset,
  Typography
} from '@vivaldis/antd-ui'
import { useTheme } from '@vivaldis/ui'
import { Link } from '@vivaldis/web-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeesSidebarEmployeeFragment as Employee } from '../../../../../../graphql/__generated__/EmployeesSidebarEmployeeFragment'

interface Props {
  employee: Employee
  selected: boolean
}

export const ListItem: FC<Props> = ({ employee, selected }) => {
  const [t] = useTranslation()
  const theme = useTheme()

  const employeeName = useMemo(
    () => `${employee.firstName} ${employee.lastName}`,
    [employee.firstName, employee.lastName]
  )

  const missingCertificatesCount = useMemo(() => {
    return 0
  }, [])

  return (
    <InfiniteList.Item key={employee.id}>
      <LinkWithStyles
        to={selected ? '/employees' : `/employees/${employee.id}`}
        selected={selected}
      >
        <Meta
          title={
            <Typography.Text preset={TextStylePreset.Semibold16} ellipsis>
              {employeeName}
            </Typography.Text>
          }
          description={
            <Typography.Text preset={TextStylePreset.Regular14} ellipsis>
              {employee.profile?.city || t('not_set')}
            </Typography.Text>
          }
        />
        <Badge
          count={missingCertificatesCount}
          style={{ backgroundColor: theme.orange6 }}
        />
      </LinkWithStyles>
    </InfiniteList.Item>
  )
}

interface LinkWithStylesProps {
  selected: boolean
}

const LinkWithStyles = styled(Link)<LinkWithStylesProps>`
  height: 64px;
  overflow: hidden;
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 8px;
  padding: 10px 18px 10px 12px;
  position: relative;
  background-color: ${props =>
    props.selected ? props.theme.extraBackground1 : props.theme.white};
  &:hover {
    background-color: ${({ selected, theme }) =>
      selected ? theme.extraBackground1 : theme.gray3};
  }
  &:focus-visible {
    background-color: ${({ selected, theme }) =>
      selected ? theme.extraBackground1 : theme.orange1};
  }
  &:after {
    content: '';
    position: absolute;
    right: 8px;
    height: 24px;
    width: 2px;
    background-color: ${props =>
      props.selected ? props.theme.orange6 : 'transparent'};
    pointer-events: none;
  }
`

const Meta = styled(InfiniteList.Item.Meta)`
  display: flex;
  align-items: center;
`

import { Table, InfiniteTable } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmployeeDetailsQuery_employee_employeeCertificates,
  EmployeeDetailsQuery_missingCertificates
} from '../../../../graphql/__generated__/EmployeeDetailsQuery'
import {
  useEmployee,
  useMissingCertificates,
  useQuery
} from '../../../../providers/QueryProvider'
import { Position } from './components/Position'
import { Type } from './components/Type'
import { Status } from './components/Status'
import { Actions } from './components/Actions'

export interface EmployeeCertificateOrMissingCertificate {
  id: string
  __typename:
    | EmployeeDetailsQuery_employee_employeeCertificates['__typename']
    | EmployeeDetailsQuery_missingCertificates['__typename']
  certificateType: string
  positionName?: string
}

interface Props {
  companyId: string
}

export const CertificatesList: FC<Props> = ({ companyId }) => {
  const [t] = useTranslation()

  const { loading: isLoading, error } = useQuery()

  const employee = useEmployee()
  const missingCertificates = useMissingCertificates()

  const existingCertificates: EmployeeDetailsQuery_employee_employeeCertificates[] =
    useMemo(() => {
      return employee?.employeeCertificates || []
    }, [employee?.employeeCertificates])

  const certificates = useMemo<
    EmployeeCertificateOrMissingCertificate[]
  >(() => {
    return [...missingCertificates, ...existingCertificates]
      .map(item => {
        switch (item.__typename) {
          case 'MissingCertificate':
            return item.certificates.map(certificateType => ({
              id: `${item.positionName} - ${certificateType}`,
              __typename: item.__typename,
              certificateType: certificateType,
              positionName: item.positionName
            }))
          case 'EmployeeCertificate':
            return {
              id: item.id,
              __typename: item.__typename,
              certificateType: item.certificateType
            }
          default:
            return {
              id: '-1',
              __typename: 'EmployeeCertificate' as any,
              certificateType: '-1'
            }
        }
      })
      .flat()
  }, [existingCertificates, missingCertificates])

  const showLoading = useMemo(
    () => isLoading && !certificates.length,
    [certificates.length, isLoading]
  )

  return (
    <InfiniteTable<EmployeeCertificateOrMissingCertificate>
      dataSource={certificates}
      rowKey="id"
      loading={showLoading}
      isLoadingMore={false}
      onLoadMore={() => {}}
      hasNextPage={false}
      error={error}
    >
      <Table.Column<EmployeeCertificateOrMissingCertificate>
        title={t(
          'screens.employee_details.tabs.certificates.table.columns.type.title'
        )}
        key="type"
        render={(value, certificate) => <Type certificate={certificate} />}
      />
      <Table.Column<EmployeeCertificateOrMissingCertificate>
        title={t(
          'screens.employee_details.tabs.certificates.table.columns.position.title'
        )}
        key="position"
        render={(value, certificate) => <Position certificate={certificate} />}
      />
      <Table.Column<EmployeeCertificateOrMissingCertificate>
        title={t(
          'screens.employee_details.tabs.certificates.table.columns.status.title'
        )}
        key="status"
        render={(value, certificate) => <Status certificate={certificate} />}
      />
      <Table.Column<EmployeeCertificateOrMissingCertificate>
        title={t(
          'screens.employee_details.tabs.certificates.table.columns.actions.title'
        )}
        key="actions"
        align="right"
        width={50}
        render={(value, certificate) => <Actions certificate={certificate} />}
      />
    </InfiniteTable>
  )
}

import styled from '@emotion/styled'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Form, Select, DatePicker } from '@vivaldis/antd-ui'
import { SearchType, useForm } from '../../../../providers/FormProvider'
import { useQuery } from '../../../../providers/QueryProvider'

interface SidebarCompaniesProps {
  selectedEmployeeId?: string
}

export const Filters: FC<SidebarCompaniesProps> = ({ selectedEmployeeId }) => {
  const [t] = useTranslation()

  const { values } = useForm()

  const {
    loading: isLoading
    // refetch
  } = useQuery()

  const searchTypeOptions = useMemo(
    () => [
      {
        label: t(
          'screens.employees.sidebar_employees.form.search_type.options.all_employees'
        ),
        value: SearchType.ALL_EMPLOYEES
      },
      {
        label: t(
          'screens.employees.sidebar_employees.form.search_type.options.missing_certificates'
        ),
        value: SearchType.MISSING_CERTIFICATES
      },
      {
        label: t(
          'screens.employees.sidebar_employees.form.search_type.options.missing_safety_answers'
        ),
        value: SearchType.MISSING_SAFETY_ANSWERS
      }
    ],
    [t]
  )

  return (
    <Base>
      <Form.Item
        name="searchType"
        label={t('screens.employees.sidebar_employees.form.search_type.label')}
        required
        hasFeedback={false}
        style={{ marginBottom: 8 }}
      >
        <Select
          name="searchType"
          placeholder={t('form.required')}
          options={searchTypeOptions}
          // onChange={handleSearchTypeChange}
        />
      </Form.Item>

      {values.searchType === SearchType.ALL_EMPLOYEES ? (
        <>
          <Form.Item
            name="name"
            label={t('screens.employees.sidebar_employees.form.name.label')}
            hasFeedback={false}
            style={{ marginBottom: 0 }}
          >
            <Input.Search
              name="name"
              placeholder={t(
                'screens.employees.sidebar_employees.form.name.placeholder'
              )}
              autoComplete="off"
              // onChange={handleSearchValueChange}
              // onSearch={handleSearch}
              loading={isLoading}
            />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t('screens.employees.sidebar_employees.form.phone.label')}
            hasFeedback={false}
            style={{ marginBottom: 0 }}
          >
            <Input.Search
              name="phone"
              placeholder={t(
                'screens.employees.sidebar_employees.form.phone.placeholder'
              )}
              autoComplete="off"
              loading={isLoading}
            />
          </Form.Item>
        </>
      ) : null}

      {values.searchType === SearchType.MISSING_CERTIFICATES ? (
        <Form.Item
          name="range"
          label={t('screens.employees.sidebar_employees.form.range.label')}
          hasFeedback={false}
          style={{ marginBottom: 0 }}
        >
          <DatePicker.RangePicker
            name="range"
            format="dd/MM/YYYY"
            allowClear={false}
          />
        </Form.Item>
      ) : null}
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
`

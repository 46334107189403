import { FC, PropsWithChildren } from 'react'
import { Typography } from '@vivaldis/antd-ui'
import styled from '@emotion/styled'
import { VivaldisLogo } from './VivaldisLogo'

export const Header: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Base>
      <VivaldisLogo />
      <Typography.Title
        level={3}
        textAlign="center"
        marginTop={16}
        marginBottom={24}
      >
        {children}
      </Typography.Title>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`

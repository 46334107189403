import { FC, PropsWithChildren } from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useTheme } from '@vivaldis/ui'
import { Breakpoints } from '@vivaldis/web-ui'
import { Shadows } from '@vivaldis/web-ui'
import { Helmet } from 'react-helmet'
import Background from './assets/background.svg'

interface ScreenBaseProps {
  title: string
}

export const ScreenBase: FC<PropsWithChildren<ScreenBaseProps>> = ({
  title,
  children
}) => {
  const theme = useTheme()
  const appName = 'VivaConnect'

  return (
    <>
      <Global
        styles={css`
          .authScreen-body {
            background-color: ${theme.volcano5};
          }
        `}
      />
      <Helmet
        bodyAttributes={{
          class: 'authScreen-body'
        }}
        titleTemplate={`%s - ${appName}`}
        defaultTitle={appName}
      >
        <title>{title}</title>
      </Helmet>
      <Base>
        <Container>
          <ContentContainer>{children}</ContentContainer>
        </Container>
      </Base>
    </>
  )
}

const Base = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: url(${Background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.white};
  ${Shadows.Hard};
  box-sizing: border-box;
  @media only screen and (max-width: ${Breakpoints.mobile}px) {
    min-width: auto;
  }
`

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 24px 40px 24px;

  @media only screen and (max-width: ${Breakpoints.mobile - 1}px) {
    margin: 24px 12px 40px 12px;
  }

  @media only screen and (min-width: ${Breakpoints.tablet}px) {
    min-width: 600px;
  }
`

import styled from '@emotion/styled'
import { DangerTriangle, ShieldDone } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { Button, Descriptions, Tag } from '@vivaldis/antd-ui'
import {
  doesEmployeeHaveExpiredContractReadyInformation,
  isEmployeeContractReady,
  useCountryName,
  useDateFormat
} from '@vivaldis/common'
import { useTranslation } from 'react-i18next'
import { EmployeeDetailsQuery_employee } from '../../../../graphql/__generated__/EmployeeDetailsQuery'

interface Props {
  employee: EmployeeDetailsQuery_employee
}

export const TopBar: FC<Props> = ({ employee }) => {
  const [t] = useTranslation()
  const theme = useTheme()

  const isContractReady = isEmployeeContractReady(employee)
  const employeeHasExpiredInformation =
    doesEmployeeHaveExpiredContractReadyInformation(employee)
  const countryName = useCountryName(employee.profile?.country)

  const employeeName = useMemo(() => {
    return `${employee.firstName} ${employee.lastName}`
  }, [employee.firstName, employee.lastName])

  const hasAddress = employee.profile?.street

  const address = useMemo(() => {
    return [
      employee.profile?.street,
      employee.profile?.streetNumber,
      employee.profile?.buildingNumber,
      ',',
      employee.profile?.zip,
      employee.profile?.city,
      ',',
      countryName
    ]
      .filter(Boolean)
      .join(' ')
      .replace('  ', ' ')
      .replace(' ,', ',')
  }, [
    countryName,
    employee.profile?.buildingNumber,
    employee.profile?.city,
    employee.profile?.street,
    employee.profile?.streetNumber,
    employee.profile?.zip
  ])

  const format = useDateFormat('d MMMM yyyy')

  const dateOfBirth = useMemo(() => {
    return employee.profile?.birthdate
      ? format(new Date(employee.profile?.birthdate))
      : ''
  }, [employee.profile?.birthdate, format])

  return (
    <Base>
      <Descriptions bordered size="small" column={2}>
        <Descriptions.Item
          label={t('screens.employee_details.top_bar.employee_name')}
        >
          <Button
            to={`/employee/${employee.id}`}
            openInSideSheet
            type="link"
            style={{ padding: 0 }}
          >
            {employeeName}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item
          label={t('screens.employee_details.top_bar.birthdate')}
        >
          {dateOfBirth || t('not_set')}
        </Descriptions.Item>
        <Descriptions.Item label={t('screens.employee_details.top_bar.mobile')}>
          {employee.mobile || t('not_set')}
        </Descriptions.Item>

        <Descriptions.Item
          label={t('screens.employee_details.top_bar.status.label')}
        >
          {isContractReady ? (
            <Tag
              icon={<ShieldDone />}
              style={{
                background: theme.StatusGreen_green1,
                borderColor: theme.StatusGreen_green4
              }}
            >
              {t('screens.employee_details.top_bar.status.contract_ready')}
            </Tag>
          ) : employeeHasExpiredInformation ? (
            <Tag
              icon={<DangerTriangle />}
              style={{
                background: theme.red1,
                borderColor: theme.red4
              }}
            >
              {t('screens.employee_details.top_bar.status.expired_information')}
            </Tag>
          ) : (
            <Tag
              icon={<DangerTriangle />}
              style={{
                background: theme.red1,
                borderColor: theme.red4
              }}
            >
              {t('screens.employee_details.top_bar.status.not_contract_ready')}
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={t('screens.employee_details.top_bar.address')}
        >
          {hasAddress ? address : t('not_set')}
        </Descriptions.Item>
      </Descriptions>
    </Base>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  box-sizing: border-box;
  margin: 2px;
`

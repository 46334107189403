import { gql } from '@apollo/client'
import { employeesSidebarEmployeeFragment } from './employeesSidebarEmployeeFragment'

export const suEmployeesSidebarQuery = gql`
  ${employeesSidebarEmployeeFragment}

  query SuEmployeesSidebarQuery(
    $name: String
    $phone: String
    $cursor: String
  ) {
    suEmployees(name: $name, phone: $phone, after: $cursor) {
      edges {
        node {
          ...EmployeesSidebarEmployeeFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

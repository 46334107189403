import { resources as commonTranslations } from '@vivaldis/common/src/i18n/resources'
import { resources as antdUITranslations } from '@vivaldis/antd-ui/src/i18n/resources'
import { resources as webUITranslations } from '@vivaldis/web-ui/src/i18n/resources'

import navigation_bar_componentTranslationsEN from '../components/NavigationBar/i18n/en.json'
import navigation_bar_componentTranslationsNL from '../components/NavigationBar/i18n/nl.json'

import FourDPermissions_componentTranslationsEN from '../components/FourDPermissions/i18n/en.json'
import FourDPermissions_componentTranslationsNL from '../components/FourDPermissions/i18n/nl.json'

import generalTranslationsEN from './en.json'
import generalTranslationsNL from './nl.json'

import employee_details_screenTranslationsEN from '../screens/Employees/screens/EmployeeDetails/i18n/en.json'
import employee_details_screenTranslationsNL from '../screens/Employees/screens/EmployeeDetails/i18n/nl.json'

import add_employee_certificate_screenTranslationsEN from '../screens/Employee/screens/AddEmployeeCertificate/i18n/en.json'
import add_employee_certificate_screenTranslationsNL from '../screens/Employee/screens/AddEmployeeCertificate/i18n/nl.json'

import edit_employee_certificate_screenTranslationsEN from '../screens/Employee/screens/EditEmployeeCertificate/i18n/en.json'
import edit_employee_certificate_screenTranslationsNL from '../screens/Employee/screens/EditEmployeeCertificate/i18n/nl.json'

import employee_profile_screenTranslationsEN from '../screens/Employee/screens/EmployeeProfile/i18n/en.json'
import employee_profile_screenTranslationsNL from '../screens/Employee/screens/EmployeeProfile/i18n/nl.json'

import set_employee_safety_answer_screenTranslationsEN from '../screens/Employee/screens/SetEmployeeSafetyAnswer/i18n/en.json'
import set_employee_safety_answer_screenTranslationsNL from '../screens/Employee/screens/SetEmployeeSafetyAnswer/i18n/nl.json'

import snooze_missing_certificate_screenTranslationsEN from '../screens/Employee/screens/SnoozeMissingCertificate/i18n/en.json'
import snooze_missing_certificate_screenTranslationsNL from '../screens/Employee/screens/SnoozeMissingCertificate/i18n/nl.json'

import employees_screenTranslationsEN from '../screens/Employees/i18n/en.json'
import employees_screenTranslationsNL from '../screens/Employees/i18n/nl.json'

import login_screenTranslationsEN from '../screens/Auth/screens/Login/i18n/en.json'
import login_screenTranslationsNL from '../screens/Auth/screens/Login/i18n/nl.json'

import register_otp_screenTranslationsEN from '../screens/Auth/screens/RegisterOTP/i18n/en.json'
import register_otp_screenTranslationsNL from '../screens/Auth/screens/RegisterOTP/i18n/nl.json'

import not_found_screenTranslationsEN from '../screens/NotFound/i18n/en.json'
import not_found_screenTranslationsNL from '../screens/NotFound/i18n/nl.json'

// done

import companies_screenTranslationsEN from '../screens/Companies/i18n/en.json'
import companies_screenTranslationsNL from '../screens/Companies/i18n/nl.json'

import add_position_screenTranslationsEN from '../screens/Companies/screens/AddPosition/i18n/en.json'
import add_position_screenTranslationsNL from '../screens/Companies/screens/AddPosition/i18n/nl.json'

import add_user_screenTranslationsEN from '../screens/Companies/screens/AddUser/i18n/en.json'
import add_user_screenTranslationsNL from '../screens/Companies/screens/AddUser/i18n/nl.json'

import add_work_post_sheet_screenTranslationsEN from '../screens/Companies/screens/AddWorkPostSheet/i18n/en.json'
import add_work_post_sheet_screenTranslationsNl from '../screens/Companies/screens/AddWorkPostSheet/i18n/nl.json'

import approve_position_screenTranslationsEN from '../screens/Companies/screens/ApprovePosition/i18n/en.json'
import approve_position_screenTranslationsNL from '../screens/Companies/screens/ApprovePosition/i18n/nl.json'

import edit_position_screenTranslationsEN from '../screens/Companies/screens/EditPosition/i18n/en.json'
import edit_position_screenTranslationsNL from '../screens/Companies/screens/EditPosition/i18n/nl.json'

import edit_work_post_sheet_screenTranslationsEN from '../screens/Companies/screens/EditWorkPostSheet/i18n/en.json'
import edit_work_post_sheet_screenTranslationsNL from '../screens/Companies/screens/EditWorkPostSheet/i18n/nl.json'

import fill_survey_screenTranslationsEN from '../screens/Companies/screens/FillSurvey/i18n/en.json'
import fill_survey_screenTranslationsNL from '../screens/Companies/screens/FillSurvey/i18n/nl.json'

import view_position_screenTranslationsEN from '../screens/Companies/screens/ViewPosition/i18n/en.json'
import view_position_screenTranslationsNL from '../screens/Companies/screens/ViewPosition/i18n/nl.json'

import view_survey_screenTranslationsEN from '../screens/Companies/screens/ViewSurvey/i18n/en.json'
import view_survey_screenTranslationsNL from '../screens/Companies/screens/ViewSurvey/i18n/nl.json'

import invite_staff_screenTranslationsEN from '../screens/Companies/screens/InviteStaff/i18n/en.json'
import invite_staff_screenTranslationsNL from '../screens/Companies/screens/InviteStaff/i18n/nl.json'

import workday_cancellation_request_decision_screenTranslationsEN from '../screens/Companies/screens/WorkdayCancellationRequestDecision/i18n/en.json'
import workday_cancellation_request_decision_screenTranslationsNL from '../screens/Companies/screens/WorkdayCancellationRequestDecision/i18n/nl.json'

import view_work_post_sheet_screenTranslationsEN from '../screens/Companies/screens/ViewWorkPostSheet/i18n/en.json'
import view_work_post_sheet_screenTranslationsNL from '../screens/Companies/screens/ViewWorkPostSheet/i18n/nl.json'

import company_screenTranslationsEN from '../screens/Companies/screens/Company/i18n/en.json'
import company_screenTranslationsNL from '../screens/Companies/screens/Company/i18n/nl.json'

import contract_list_screenTranslationsEN from '../screens/Contracts/ContractList/i18n/en.json'
import contract_list_screenTranslationsNL from '../screens/Contracts/ContractList/i18n/nl.json'

import push_contract_screenTranslationsEN from '../screens/Contracts/PushContract/i18n/en.json'
import push_contract_screenTranslationsNL from '../screens/Contracts/PushContract/i18n/nl.json'

import view_contract_screenTranslationsEN from '../screens/Contracts/ViewContract/i18n/en.json'
import view_contract_screenTranslationsNL from '../screens/Contracts/ViewContract/i18n/nl.json'

import user_list_screenTranslationsEN from '../screens/Users/UserList/i18n/en.json'
import user_list_screenTranslationsNL from '../screens/Users/UserList/i18n/nl.json'

import edit_user_screenTranslationsEN from '../screens/Users/EditUser/i18n/en.json'
import edit_user_screenTranslationsNL from '../screens/Users/EditUser/i18n/nl.json'

import view_user_screenTranslationsEN from '../screens/Users/ViewUser/i18n/en.json'
import view_user_screenTranslationsNL from '../screens/Users/ViewUser/i18n/nl.json'

export const resources = {
  en: {
    // @vivaldis/antd-ui
    antd_ui: antdUITranslations.en,
    // @vivaldis/common
    common: commonTranslations.en,
    components: {
      navigation_bar: navigation_bar_componentTranslationsEN,
      four_d_permission: FourDPermissions_componentTranslationsEN
    },
    translation: {
      ...generalTranslationsEN,
      screens: {
        employee_details: employee_details_screenTranslationsEN,
        add_employee_certificate: add_employee_certificate_screenTranslationsEN,
        edit_employee_certificate:
          edit_employee_certificate_screenTranslationsEN,
        employee_profile: employee_profile_screenTranslationsEN,
        set_employee_safety_answer:
          set_employee_safety_answer_screenTranslationsEN,
        snooze_missing_certificate:
          snooze_missing_certificate_screenTranslationsEN,
        employees: employees_screenTranslationsEN,
        login: login_screenTranslationsEN,
        register_otp: register_otp_screenTranslationsEN,
        not_found: not_found_screenTranslationsEN,
        companies: {
          ...companies_screenTranslationsEN,
          add_position: add_position_screenTranslationsEN,
          add_user: add_user_screenTranslationsEN,
          add_work_post_sheet: add_work_post_sheet_screenTranslationsEN,
          approve_position: approve_position_screenTranslationsEN,
          edit_position: edit_position_screenTranslationsEN,
          edit_work_post_sheet: edit_work_post_sheet_screenTranslationsEN,
          fill_survey: fill_survey_screenTranslationsEN,
          invite_staff: invite_staff_screenTranslationsEN,
          view_position: view_position_screenTranslationsEN,
          view_survey: view_survey_screenTranslationsEN,
          view_work_post_sheet: view_work_post_sheet_screenTranslationsEN,
          workday_cancellation_request_decision:
            workday_cancellation_request_decision_screenTranslationsEN,
          company: company_screenTranslationsEN
        },
        contract_list: contract_list_screenTranslationsEN,
        push_contract: push_contract_screenTranslationsEN,
        view_contract: view_contract_screenTranslationsEN,
        // Users
        user_list: user_list_screenTranslationsEN,
        edit_user: edit_user_screenTranslationsEN,
        view_user: view_user_screenTranslationsEN
      }
    },
    // @vivaldis/web-ui
    web_ui: webUITranslations.en
  },
  nl: {
    // @vivaldis/antd-ui
    antd_ui: antdUITranslations.nl,
    // @vivaldis/common
    common: commonTranslations.nl,
    components: {
      navigation_bar: navigation_bar_componentTranslationsNL,
      four_d_permission: FourDPermissions_componentTranslationsNL
    },
    translation: {
      ...generalTranslationsNL,
      screens: {
        employee_details: employee_details_screenTranslationsNL,
        add_employee_certificate: add_employee_certificate_screenTranslationsNL,
        edit_employee_certificate:
          edit_employee_certificate_screenTranslationsNL,
        employee_profile: employee_profile_screenTranslationsNL,
        set_employee_safety_answer:
          set_employee_safety_answer_screenTranslationsNL,
        snooze_missing_certificate:
          snooze_missing_certificate_screenTranslationsNL,
        employees: employees_screenTranslationsNL,
        login: login_screenTranslationsNL,
        register_otp: register_otp_screenTranslationsNL,
        not_found: not_found_screenTranslationsNL,
        companies: {
          ...companies_screenTranslationsNL,
          add_position: add_position_screenTranslationsNL,
          add_user: add_user_screenTranslationsNL,
          add_work_post_sheet: add_work_post_sheet_screenTranslationsNl,
          approve_position: approve_position_screenTranslationsNL,
          edit_position: edit_position_screenTranslationsNL,
          edit_work_post_sheet: edit_work_post_sheet_screenTranslationsNL,
          fill_survey: fill_survey_screenTranslationsNL,
          view_position: view_position_screenTranslationsNL,
          view_survey: view_survey_screenTranslationsNL,
          view_work_post_sheet: view_work_post_sheet_screenTranslationsNL,
          workday_cancellation_request_decision:
            workday_cancellation_request_decision_screenTranslationsNL,
          invite_staff: invite_staff_screenTranslationsNL,
          company: company_screenTranslationsNL
        },
        contract_list: contract_list_screenTranslationsNL,
        push_contract: push_contract_screenTranslationsNL,
        view_contract: view_contract_screenTranslationsNL,
        // Users
        user_list: user_list_screenTranslationsNL,
        edit_user: edit_user_screenTranslationsNL,
        view_user: view_user_screenTranslationsNL
      }
    },
    // @vivaldis/web-ui
    web_ui: webUITranslations.nl
  }
} as const

import { QueryResult, useQuery } from '@apollo/client'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'
import {
  UnansweredSafetyQuestionsTabQuery,
  UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions,
  UnansweredSafetyQuestionsTabQueryVariables
} from '../graphql/__generated__/UnansweredSafetyQuestionsTabQuery'
import { useParams } from '../../../hooks/useParams'
import { unansweredUnansweredSafetyQuestionsTabQuery } from '../graphql/unansweredSafetyQuestionsTabQuery'

export interface QueryContextValue
  extends QueryResult<
    UnansweredSafetyQuestionsTabQuery,
    UnansweredSafetyQuestionsTabQueryVariables
  > {}

export const QueryContext = createContext<QueryContextValue | undefined>(
  undefined
)

export function useUnansweredSafetyQuestionsTabQuery(): QueryContextValue {
  return useContext(QueryContext)!
}

export function useUnansweredSafetyQuestions(): UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions[] {
  const { data } = useUnansweredSafetyQuestionsTabQuery()

  return useMemo(() => {
    return data?.unansweredSafetyQuestions || []
  }, [data])
}

export const UnansweredSafetyQuestionsTabQueryProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const { employeeId } = useParams()

  const queryResult = useQuery<
    UnansweredSafetyQuestionsTabQuery,
    UnansweredSafetyQuestionsTabQueryVariables
  >(unansweredUnansweredSafetyQuestionsTabQuery, {
    variables: {
      employeeId
    }
  })

  return (
    <QueryContext.Provider value={queryResult}>
      {children}
    </QueryContext.Provider>
  )
}

import { Tag } from '@vivaldis/antd-ui'
import { DangerTriangle, ShieldDone } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeeCertificateOrMissingCertificate } from '../index'

interface Props {
  certificate: EmployeeCertificateOrMissingCertificate
}

export const Status: FC<Props> = ({ certificate }) => {
  const [t] = useTranslation()
  const theme = useTheme()

  const isActiveCertificate = useMemo(() => {
    switch (certificate.__typename) {
      case 'MissingCertificate':
        return false
      case 'EmployeeCertificate':
        return true
    }
    return false
  }, [certificate])

  const isMissingCertificate = !isActiveCertificate

  return (
    <>
      {isActiveCertificate ? (
        <Tag
          icon={<ShieldDone />}
          style={{
            background: theme.StatusGreen_green1,
            borderColor: theme.StatusGreen_green4
          }}
        >
          {t(
            'screens.employee_details.tabs.certificates.table.columns.status.statuses.active'
          )}
        </Tag>
      ) : null}
      {isMissingCertificate ? (
        <Tag
          icon={<DangerTriangle />}
          style={{
            background: theme.red1,
            borderColor: theme.red4
          }}
        >
          {t(
            'screens.employee_details.tabs.certificates.table.columns.status.statuses.missing_certificate'
          )}
        </Tag>
      ) : null}
    </>
  )
}

import { Routes, Route } from 'react-router-dom'
import { lazy, Suspense, FC } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'

const EditUser = lazy(() => import('./EditUser'))
const UserList = lazy(() => import('./UserList'))
const ViewUser = lazy(() => import('./ViewUser'))

export const UsersRoutes: FC = () => {
  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route path="/" element={<UserList />} />
        <Route path="/edit-user/:userId" element={<EditUser />} />
        <Route path="/view-user/:userId" element={<ViewUser />} />
      </Routes>
    </Suspense>
  )
}

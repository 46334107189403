import { FC } from 'react'
import { UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions } from '../../../graphql/__generated__/UnansweredSafetyQuestionsTabQuery'

interface Props {
  unansweredSafetyQuestion: UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions
}

export const Position: FC<Props> = ({ unansweredSafetyQuestion }) => {
  return <>{unansweredSafetyQuestion.name}</>
}

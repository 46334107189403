import { format as formatFns } from 'date-fns'
import { useDateFnsLocale } from './useDateFnsLocale'
import { useCallback } from 'react'

export const useFormat = () => {
  const locale = useDateFnsLocale()

  return useCallback(
    (...params: Parameters<typeof formatFns>) => {
      const [date, format, options = {}] = params
      return formatFns(date, format, {
        locale,
        ...options
      })
    },
    [locale]
  )
}

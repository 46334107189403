import styled from '@emotion/styled'
import { Button, Spin } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '../../providers/QueryProvider'

export const ScreenContent: FC = () => {
  const [t] = useTranslation()
  const { loading, qrCode } = useQuery()

  console.log(useQuery())
  return (
    <>
      <DescriptionText>{t('screens.register_otp.description')}</DescriptionText>
      <Spin size="large" spinning={loading}>
        <QRCodeBase dangerouslySetInnerHTML={{ __html: qrCode || '' }} />
      </Spin>
      <Button type="primary" to="/login">
        {t('screens.register_otp.go_next_button')}
      </Button>
    </>
  )
}

const DescriptionText = styled('p')`
  max-width: 600px;
  text-align: center;
  margin-bottom: 24px;
`

const QRCodeBase = styled('div')`
  height: 488px;
  width: 488px;
  background-color: ${props => props.theme.white};
  padding: 16px;
  margin-bottom: 24px;
`

import { FC } from 'react'
import { ProgressIndicator } from '@vivaldis/web-ui'
import styled from '@emotion/styled'

const Base = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
`

export const LoadingState: FC = () => {
  return (
    <Base>
      <ProgressIndicator />
    </Base>
  )
}

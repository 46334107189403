import { css, Global } from '@emotion/react'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import { ProgressBar } from '@vivaldis/web-ui'
import { useHasSession } from '@vivaldis/session'
import { Routes } from '../screens/routes'
import { NavigationBar } from '../components/NavigationBar'
import DevTools from './Dev/DevTools'
import { Locale } from './Locale'

export const App: FC = () => {
  const hasSession = useHasSession()
  const theme = useTheme()

  return (
    <>
      {hasSession && <NavigationBar />}
      <Routes />
      {process.env.NODE_ENV !== 'production' ? <DevTools /> : null}
      <ProgressBar />
      {hasSession ? (
        <Global
          styles={
            // We set the background-color of the app here when the app is loaded to not override the splash screen background-color
            css`
              html,
              body {
                background-color: ${theme.extraBackground1} !important;
              }
            `
          }
        />
      ) : null}
      <Locale />
    </>
  )
}

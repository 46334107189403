import {
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  useMemo
} from 'react'
import { useQuery as useQueryBase, QueryResult } from '@apollo/client'
import {
  EmployeeDetailsQuery,
  EmployeeDetailsQuery_employee,
  EmployeeDetailsQuery_missingCertificates,
  EmployeeDetailsQueryVariables
} from '../graphql/__generated__/EmployeeDetailsQuery'
import { employeeDetailsQuery } from '../graphql/employeeDetailsQuery'
import { useParams } from '../hooks/useParams'

export interface QueryContextValue
  extends QueryResult<EmployeeDetailsQuery, EmployeeDetailsQueryVariables> {}

export const QueryContext = createContext<QueryContextValue | undefined>(
  undefined
)

export function useQuery(): QueryContextValue {
  return useContext(QueryContext)!
}

export function useEmployee(): EmployeeDetailsQuery_employee | null {
  const { data } = useQuery()
  return useMemo(() => {
    return data?.employee || null
  }, [data?.employee])
}

export function useMissingCertificates(): EmployeeDetailsQuery_missingCertificates[] {
  const { data } = useQuery()
  return useMemo(() => {
    return data?.missingCertificates || []
  }, [data?.missingCertificates])
}

export const QueryProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { employeeId } = useParams()
  const queryResult = useQueryBase<
    EmployeeDetailsQuery,
    EmployeeDetailsQueryVariables
  >(employeeDetailsQuery, {
    variables: {
      employeeId: String(employeeId)
    }
  })
  return (
    <QueryContext.Provider value={queryResult}>
      {children}
    </QueryContext.Provider>
  )
}

import { QueryResult, useQuery } from '@apollo/client'
import { format } from 'date-fns'
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo
} from 'react'
import {
  PayslipsTabPayslipsQuery,
  PayslipsTabPayslipsQuery_payslips,
  PayslipsTabPayslipsQueryVariables
} from '../graphql/__generated__/PayslipsTabPayslipsQuery'
import { payslipsTabPayslipsQuery } from '../graphql/payslipsTabPayslipsQuery'
import { useParams } from '../../../hooks/useParams'
import { usePayslipsTabSearchFormSearchForm } from './PayslipsTabSearchFormProvider'

export interface QueryContextValue
  extends QueryResult<
    PayslipsTabPayslipsQuery,
    PayslipsTabPayslipsQueryVariables
  > {}

export const QueryContext = createContext<QueryContextValue | undefined>(
  undefined
)

export function usePayslipsTabPayslips(): QueryContextValue {
  return useContext(QueryContext)!
}

export function usePayslips(): PayslipsTabPayslipsQuery_payslips[] {
  const { data } = usePayslipsTabPayslips()

  return useMemo(() => {
    // return [
    //   {
    //     __typename: 'Payslip',
    //     creationDate: null,
    //     key: 'IN25951990DBAT',
    //     pdfUrl: 'http://www.africau.edu/images/default/sample.pdf',
    //     wageDate: null
    //   },
    //   {
    //     __typename: 'Payslip',
    //     creationDate: '2022-05-04',
    //     key: 'IN258E18E4BBAT',
    //     pdfUrl: 'http://www.africau.edu/images/default/sample.pdf',
    //     wageDate: '2022-05-01'
    //   },
    //   {
    //     __typename: 'Payslip',
    //     creationDate: '2022-04-27',
    //     key: 'IN258718CFEBAT',
    //     pdfUrl: 'http://www.africau.edu/images/default/sample.pdf',
    //     wageDate: '2022-04-24'
    //   }
    // ]
    return data?.payslips || []
  }, [data])
}

export const PayslipsTabPayslipsQueryProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const { employeeId } = useParams()

  const { values } = usePayslipsTabSearchFormSearchForm()

  const queryVariables: PayslipsTabPayslipsQueryVariables = useMemo(() => {
    const [wageStart, wageEnd] = values.wageRange || []
    const [calculationStart, calculationEnd] = values.calculationRange || []

    return {
      employeeId,
      // wage
      ...(wageStart ? { wageStart: format(wageStart, 'yyyy-MM-dd') } : {}),
      ...(wageEnd ? { periodEnd: format(wageEnd, 'yyyy-MM-dd') } : {}),
      // calculation
      ...(calculationStart
        ? { calculationStart: format(calculationStart, 'yyyy-MM-dd') }
        : {}),
      ...(calculationEnd
        ? { calculationEnd: format(calculationEnd, 'yyyy-MM-dd') }
        : {})
    }
  }, [employeeId, values])

  const queryResult = useQuery<
    PayslipsTabPayslipsQuery,
    PayslipsTabPayslipsQueryVariables
  >(payslipsTabPayslipsQuery, {
    variables: queryVariables
  })

  return (
    <QueryContext.Provider value={queryResult}>
      {children}
    </QueryContext.Provider>
  )
}

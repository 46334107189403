import { gql } from '@apollo/client'
import { getApolloClient } from '@vivaldis/common'
import { SuMissingSafetyAnswerReminderInput } from '../../../../../typings/__generated__'
import {
  SuMissingSafetyAnswerReminderMutation,
  SuMissingSafetyAnswerReminderMutationVariables
} from './__generated__/SuMissingSafetyAnswerReminderMutation'

const mutation = gql`
  mutation SuMissingSafetyAnswerReminderMutation(
    $input: SuMissingSafetyAnswerReminderInput!
  ) {
    suMissingSafetyAnswerReminder(input: $input) {
      status
    }
  }
`

export const suMissingSafetyAnswerReminderMutation = (
  input: SuMissingSafetyAnswerReminderInput
) => {
  const apolloClient = getApolloClient()
  return apolloClient.mutate<
    SuMissingSafetyAnswerReminderMutation,
    SuMissingSafetyAnswerReminderMutationVariables
  >({
    mutation: mutation,
    variables: {
      input: input
    }
  })
}

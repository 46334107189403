import { Routes, Route } from 'react-router-dom'
import { lazy, Suspense, FC } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'

const ContractList = lazy(() => import('./ContractList'))
const PushContract = lazy(() => import('./PushContract'))
const ViewContract = lazy(() => import('./ViewContract'))

export const ContractsRoutes: FC = () => {
  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route path="/" element={<ContractList />} />
        <Route path="/push-contract/:id" element={<PushContract />} />
        <Route path="/view-contract/:id" element={<ViewContract />} />
      </Routes>
    </Suspense>
  )
}

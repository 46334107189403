import { Routes, Route } from 'react-router-dom'
import { FC, lazy, Suspense } from 'react'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'

const Company = lazy(() => import('./screens/Company/routes'))
const AddPosition = lazy(() => import('./screens/AddPosition'))
const AddUser = lazy(() => import('./screens/AddUser'))
const AddWorkPostSheet = lazy(() => import('./screens/AddWorkPostSheet'))
const ApprovePosition = lazy(() => import('./screens/ApprovePosition'))
const EditPosition = lazy(() => import('./screens/EditPosition'))
const EditWorkPostSheet = lazy(() => import('./screens/EditWorkPostSheet'))
const FillSurvey = lazy(() => import('./screens/FillSurvey'))
const InviteStaff = lazy(() => import('./screens/InviteStaff'))
const ViewPosition = lazy(() => import('./screens/ViewPosition'))
const ViewSurvey = lazy(() => import('./screens/ViewSurvey'))
const ViewWorkPostSheet = lazy(() => import('./screens/ViewWorkPostSheet'))
const WorkdayCancellationRequestDecision = lazy(
  () => import('./screens/WorkdayCancellationRequestDecision')
)

export const CompaniesRoutes: FC = () => {
  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Routes>
        <Route path="*" element={<Company />} />
        <Route path=":companyId/add-position" element={<AddPosition />} />
        <Route path=":companyId/add-user" element={<AddUser />} />
        <Route
          path=":companyId/add-work-post-sheet/:positionId"
          element={<AddWorkPostSheet />}
        />
        <Route
          path=":companyId/approve-position/:positionId"
          element={<ApprovePosition />}
        />
        <Route
          path=":companyId/edit-position/:positionId"
          element={<EditPosition />}
        />
        <Route
          path=":companyId/edit-work-post-sheet/:positionId"
          element={<EditWorkPostSheet />}
        />
        <Route
          path=":companyId/fill-survey/:surveyId"
          element={<FillSurvey />}
        />
        <Route
          path=":companyId/view-position/:positionId"
          element={<ViewPosition />}
        />
        <Route
          path=":companyId/view-survey/:surveyId"
          element={<ViewSurvey />}
        />
        <Route
          path=":companyId/view-work-post-sheet/:positionId"
          element={<ViewWorkPostSheet />}
        />
        <Route
          path=":companyId/workday-cancellation-request-decision/:contractId"
          element={<WorkdayCancellationRequestDecision />}
        />
        <Route path=":companyId/invite-staff" element={<InviteStaff />} />
      </Routes>
    </Suspense>
  )
}

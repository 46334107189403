import { FC } from 'react'
import styled from '@emotion/styled'
import { UnansweredSafetyQuestionsList } from './components/UnansweredSafetyQuestionsList'
import { UnansweredSafetyQuestionsTabQueryProvider } from './providers/UnansweredSafetyQuestionsTabQueryProvider'

export const UnansweredSafetyQuestionsTab: FC = () => {
  return (
    <UnansweredSafetyQuestionsTabQueryProvider>
      <Base>
        <UnansweredSafetyQuestionsList />
      </Base>
    </UnansweredSafetyQuestionsTabQueryProvider>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 175px;
`

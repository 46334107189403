import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const SearchSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49976 11.738C3.49976 7.195 7.19576 3.5 11.7388 3.5C16.2808 3.5 19.9768 7.195 19.9768 11.738C19.9768 16.281 16.2808 19.977 11.7388 19.977C7.19576 19.977 3.49976 16.281 3.49976 11.738ZM1.99976 11.738C1.99976 17.108 6.36876 21.477 11.7388 21.477C14.0354 21.477 16.1488 20.6778 17.8156 19.343L20.9842 22.5027C21.1312 22.6487 21.3232 22.7217 21.5142 22.7217C21.7062 22.7217 21.8992 22.6487 22.0452 22.5007C22.3382 22.2077 22.3372 21.7337 22.0442 21.4407L18.9128 18.3172C20.5042 16.5829 21.4768 14.2719 21.4768 11.738C21.4768 6.368 17.1088 2 11.7388 2C6.36876 2 1.99976 6.368 1.99976 11.738Z"
    />
  </svg>
)

export const Search = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={SearchSvg} {...props} ref={ref} />
)

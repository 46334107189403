import { TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { useCertificateTypeOptions } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { EmployeeCertificateOrMissingCertificate } from '../index'

interface Props {
  certificate: EmployeeCertificateOrMissingCertificate
}

export const Type: FC<Props> = ({ certificate }) => {
  const certificateTypesOptions = useCertificateTypeOptions([
    certificate.certificateType
  ])

  const certificateName = useMemo(() => {
    return (
      certificateTypesOptions.find(
        certificateTypesOption =>
          certificateTypesOption.value === certificate.certificateType
      )?.label || certificate.certificateType
    )
  }, [certificate, certificateTypesOptions])

  return (
    <Typography.Text preset={TextStylePreset.Regular14}>
      {certificateName}
    </Typography.Text>
  )
}

import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Screen } from '@vivaldis/web-ui'
import { NotFoundState } from '../components/NotFoundState'
import { ErrorState } from '../components/ErrorState'
import { LoadingState } from '../components/LoadingState'
import { EmployeeDetailsContent } from '../components/EmployeeDetailsContent'
import { useEmployee, useQuery } from '../providers/QueryProvider'

export const EmployeeDetailsScreen: FC = () => {
  const [t] = useTranslation()

  const employee = useEmployee()
  const { loading, error } = useQuery()

  const title = useMemo(() => {
    return employee
      ? `${employee.firstName} ${employee.lastName}`
      : t('screens.employee_details.title')
  }, [employee, t])

  return (
    <Screen title={title}>
      {error ? (
        <ErrorState />
      ) : employee && employee.id ? (
        <EmployeeDetailsContent employee={employee} />
      ) : loading ? (
        <LoadingState />
      ) : (
        <NotFoundState />
      )}
    </Screen>
  )
}

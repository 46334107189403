import { useDateFormat } from '@vivaldis/common'
import { FC, useMemo } from 'react'
import { PayslipsTabPayslipsQuery_payslips as Payslip } from '../../../graphql/__generated__/PayslipsTabPayslipsQuery'

interface Props {
  payslip: Payslip
}

export const CreationDate: FC<Props> = ({ payslip }) => {
  const formatDate = useDateFormat('dd/MM/yyyy')

  const text = useMemo(() => {
    if (payslip.creationDate) {
      return formatDate(new Date(payslip.creationDate))
    }
    return null
  }, [formatDate, payslip.creationDate])

  return <>{text}</>
}

import { Tag } from '@vivaldis/antd-ui'
import { DangerTriangle } from '@vivaldis/icons'
import { useTheme } from '@vivaldis/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const Status: FC = () => {
  const [t] = useTranslation()
  const theme = useTheme()

  return (
    <Tag
      icon={<DangerTriangle />}
      style={{
        background: theme.red1,
        borderColor: theme.red4
      }}
    >
      {t(
        'screens.employee_details.tabs.unanswered_safety_questions.table.columns.status.statuses.missing_safety_answer'
      )}
    </Tag>
  )
}

import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useNavigate } from 'react-router-dom'

import { isAxiosError } from '@vivaldis/common'
import { registerOtpRequest } from '../api/registerOtpRequest'
import { useLocationState } from '../hooks/useLocationState'

export interface QueryContextValue {
  loading: boolean
  qrCode: string | undefined
  error: string | undefined
}

export const QueryContext = createContext<QueryContextValue>({
  loading: false,
  qrCode: undefined,
  error: undefined
})

export function useQuery(): QueryContextValue {
  return useContext(QueryContext)!
}

export const QueryProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [qrCode, setQrCode] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const locationState = useLocationState()
  const navigate = useNavigate()

  const fetchOTP = useCallback(async () => {
    console.log('fetchOTP', locationState)
    try {
      if (!locationState?.email || !locationState?.password) {
        console.log('fetchOTP no email or password')
        return navigate('/login', {
          replace: true
        })
      }
      setLoading(true)
      setError(undefined)
      const response = await registerOtpRequest({
        email: locationState?.email || '',
        password: locationState?.password || ''
      })

      setQrCode(response.data.qr_code)
    } catch (e) {
      console.log('fetchOTP e', e)
      if (isAxiosError(e)) {
        const errorMessage = e?.response?.data?.message || e?.message
        setError(errorMessage)
      } else {
        setError('Error. no_qr_code')
      }
    } finally {
      setLoading(false)
    }
  }, [locationState, navigate])

  useEffect(() => {
    fetchOTP()
  }, [fetchOTP])

  const value = useMemo(
    () => ({
      loading: loading,
      qrCode: qrCode,
      error: error
    }),
    [error, loading, qrCode]
  )

  return <QueryContext.Provider value={value}>{children}</QueryContext.Provider>
}

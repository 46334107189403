import { lazy, Suspense, FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'

const AddEmployeeCertificate = lazy(
  () =>
    import(
      /* webpackChunkName: "AddEmployeeCertificate" */ './screens/AddEmployeeCertificate'
    )
)
const EditEmployeeCertificate = lazy(
  () =>
    import(
      /* webpackChunkName: "EditEmployeeCertificate" */ './screens/EditEmployeeCertificate'
    )
)
const EmployeeProfile = lazy(
  () =>
    import(
      /* webpackChunkName: "EmployeeProfile" */ './screens/EmployeeProfile'
    )
)

const SetEmployeeSafetyAnswer = lazy(
  () =>
    import(
      /* webpackChunkName: "SetEmployeeSafetyAnswer" */ './screens/SetEmployeeSafetyAnswer'
    )
)

const SnoozeMissingCertificate = lazy(
  () =>
    import(
      /* webpackChunkName: "SnoozeMissingCertificate" */ './screens/SnoozeMissingCertificate'
    )
)

export const EmployeeRoutes: FC = () => (
  <Suspense fallback={ProgressBarSuspenseFallback}>
    <Routes>
      <Route path=":id" element={<EmployeeProfile />} />
      <Route
        path=":employeeId/add-employee-certificate"
        element={<AddEmployeeCertificate />}
      />
      <Route
        path=":employeeId/edit-employee-certificate/:certificateId"
        element={<EditEmployeeCertificate />}
      />
      <Route
        path=":employeeId/set-employee-safety-answer/:positionId"
        element={<SetEmployeeSafetyAnswer />}
      />
      <Route
        path=":employeeId/snooze-missing-certificate/:certificateType"
        element={<SnoozeMissingCertificate />}
      />
    </Routes>
  </Suspense>
)

import { addMonths, addWeeks, endOfMonth, startOfISOWeek } from 'date-fns'
import {
  Formik,
  useFormikContext,
  FormikContextType,
  FormikHelpers
} from 'formik'
import * as Yup from 'yup'
import { Form } from '@vivaldis/antd-ui'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'

export enum SearchType {
  ALL_EMPLOYEES = 'ALL_EMPLOYEES',
  MISSING_CERTIFICATES = 'MISSING_CERTIFICATES',
  MISSING_SAFETY_ANSWERS = 'MISSING_SAFETY_ANSWERS'
}

export interface FormValues {
  searchType: SearchType
  name: string
  phone: string
  // [periodStart, periodEnd]
  range: [Date, Date]
}

export function useForm(): FormikContextType<FormValues> {
  return useFormikContext<FormValues>()!
}

export const FormProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const initialValues: FormValues = useMemo(
    () => ({
      searchType: SearchType.ALL_EMPLOYEES,
      name: '',
      phone: '',
      range: [
        // periodStart - by default should be 2 weeks in the past
        addWeeks(startOfISOWeek(new Date()), -1),
        // periodEnd - by default should be 1 month in the future
        addMonths(endOfMonth(new Date()), 1)
      ]
    }),
    []
  )

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        searchType: Yup.string().required(),
        name: Yup.string().nullable().notRequired()
      }),
    []
  )

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(false)
    },
    []
  )

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form id="employees-search-form" name="employees-search-form">
        {children}
      </Form>
    </Formik>
  )
}

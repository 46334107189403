import { DatePicker, Form, Space } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const SearchForm: FC = () => {
  const [t] = useTranslation()

  return (
    <Form
      name="payslips-search-form"
      id="cy-payslips-search-form"
      style={{ marginBottom: 16 }}
    >
      <Space>
        <Form.Item
          label={t(
            'screens.employee_details.tabs.payslips.search_form.calculation_range.label'
          )}
          name="calculationRange"
          style={{ marginBottom: 0 }}
        >
          <DatePicker.RangePicker
            name="calculationRange"
            format="dd/MM/YYYY"
            allowClear={true}
          />
        </Form.Item>
        <Form.Item
          label={t(
            'screens.employee_details.tabs.payslips.search_form.wage_range.label'
          )}
          name="wageRange"
          style={{ marginBottom: 0 }}
        >
          <DatePicker.RangePicker
            name="wageRange"
            format="dd/MM/YYYY"
            allowClear={true}
          />
        </Form.Item>
      </Space>
    </Form>
  )
}

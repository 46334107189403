import { call, takeEvery } from 'redux-saga/effects'
import { SessionActionType } from '@vivaldis/session'
import { MainNavigateGlobalProvider } from '@vivaldis/web-ui'

function* signOutComplete() {
  yield call(async () => {
    const navigate = await MainNavigateGlobalProvider.getNavigate()
    navigate('/', { replace: true })
  })
}

export function* session() {
  yield takeEvery(SessionActionType.SignOutComplete, signOutComplete)
}

import { FC } from 'react'
import { useMatch } from 'react-router-dom'
import { SidebarCompaniesContent } from './components/SidebarCompaniesContent'
import { FormProvider } from './providers/FormProvider'
import { SuEmployeesWithMissingCertificatesQueryProvider } from './providers/SuEmployeesWithMissingCertificatesQueryProvider'
import { SuEmployeesWithMissingSafetyAnswersQueryProvider } from './providers/SuEmployeesWithMissingSafetyAnswersQueryProvider'
import { QueryProvider } from './providers/QueryProvider'
import { SuEmployeesProvider } from './providers/SuEmployeesProvider'

export const SidebarEmployees: FC = () => {
  const employeeIdMatch = useMatch('/employees/:companyId')
  return (
    <FormProvider>
      <SuEmployeesProvider>
        <SuEmployeesWithMissingCertificatesQueryProvider>
          <SuEmployeesWithMissingSafetyAnswersQueryProvider>
            <QueryProvider>
              <SidebarCompaniesContent
                selectedEmployeeId={employeeIdMatch?.params.companyId}
              />
            </QueryProvider>
          </SuEmployeesWithMissingSafetyAnswersQueryProvider>
        </SuEmployeesWithMissingCertificatesQueryProvider>
      </SuEmployeesProvider>
    </FormProvider>
  )
}

import { FC, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import {
  MasterDetail,
  ProgressBarSuspenseFallback,
  Screen
} from '@vivaldis/web-ui'
import { useTranslation } from 'react-i18next'
import { SidebarEmployees } from './SidebarEmployees'
import CompanyScreen from './screens/EmployeeDetails'

export const EmployeesRoutes: FC = () => {
  const [t] = useTranslation()
  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <Screen title={t('screens.employees.title')}>
        <MasterDetail
          MasterComponent={SidebarEmployees}
          DetailComponent={
            <Routes>
              <Route path=":employeeId" element={<CompanyScreen />} />
            </Routes>
          }
        />
      </Screen>
    </Suspense>
  )
}

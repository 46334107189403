import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const NotificationSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.31614 7.695C6.31614 5.238 8.75214 2.5 12.2471 2.5C15.7421 2.5 18.1781 5.238 18.1781 7.695C18.1781 9.774 18.7551 10.735 19.2651 11.583C19.6741 12.264 19.9971 12.802 20.0001 13.906C19.8341 15.813 18.5501 16.848 12.2471 16.848C5.90914 16.848 4.66414 15.857 4.49714 13.971C4.49714 12.802 4.82014 12.264 5.22914 11.583C5.73914 10.735 6.31614 9.774 6.31614 7.695ZM3.00014 14.035C3.34514 18.018 7.57114 18.348 12.2471 18.348C16.9231 18.348 21.1481 18.018 21.4971 13.971C21.4971 12.387 20.9941 11.549 20.5501 10.811C20.1011 10.063 19.6781 9.358 19.6781 7.695C19.6781 4.462 16.6921 1 12.2471 1C7.80213 1 4.81614 4.462 4.81614 7.695C4.81614 9.358 4.39314 10.063 3.94414 10.811C3.50014 11.549 2.99714 12.387 3.00014 14.035ZM9.20927 21.108C10.0143 22.005 11.0753 22.499 12.1963 22.5H12.1983C13.3223 22.5 14.3853 22.006 15.1933 21.109C15.4703 20.802 15.4453 20.327 15.1373 20.05C14.8303 19.773 14.3563 19.798 14.0783 20.105C13.5593 20.683 12.8913 21 12.1983 21H12.1973C11.5073 21 10.8423 20.683 10.3243 20.106C10.0473 19.797 9.57327 19.772 9.26527 20.05C8.95727 20.326 8.93227 20.801 9.20927 21.108Z"
    />
  </svg>
)

export const Notification = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={NotificationSvg} {...props} ref={ref} />
)

import styled from '@emotion/styled'
import { FC } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useTranslation } from 'react-i18next'
import { Button, Divider } from '@vivaldis/antd-ui'
import { Master } from '@vivaldis/web-ui'
import { useQuery } from '../../providers/QueryProvider'
import { EmployeeList } from './components/EmployeeList'
import { Filters } from './components/Filters'

interface SidebarCompaniesProps {
  selectedEmployeeId?: string
}

export const SidebarCompaniesContent: FC<SidebarCompaniesProps> = ({
  selectedEmployeeId
}) => {
  const [t] = useTranslation()

  const { loading, isLoadingMore, hasNextPage, loadMore, error } = useQuery()

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading || isLoadingMore,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px'
  })

  return (
    <Master ref={rootRef}>
      <Base>
        <Filters />
        <Divider />
        <EmployeeList
          sentryRef={sentryRef}
          selectedEmployeeId={selectedEmployeeId}
        />
        {hasNextPage && (
          <Button
            onClick={loadMore}
            type="text"
            shape="round"
            size="large"
            loading={isLoadingMore}
            style={{ margin: 32 }}
          >
            {t('screens.employees.sidebar_employees.show_more')}
          </Button>
        )}
      </Base>
    </Master>
  )
}

const Base = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
`

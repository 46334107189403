import { ClockCircleOutlined } from '@ant-design/icons'
import { App, Button, Space } from '@vivaldis/antd-ui'
import { Notification, PaperFail } from '@vivaldis/icons'
import { useGoBackTo } from '@vivaldis/web-ui'
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActions } from '../../../../../providers/ActionsProvider'
import { useEmployee } from '../../../../../providers/QueryProvider'
import { EmployeeCertificateOrMissingCertificate } from '../index'

interface Props {
  certificate: EmployeeCertificateOrMissingCertificate
}

export const Actions: FC<Props> = ({ certificate }) => {
  const [t] = useTranslation()
  const goBackTo = useGoBackTo()
  const employee = useEmployee()

  const isActiveCertificate = useMemo(() => {
    switch (certificate.__typename) {
      case 'MissingCertificate':
        return false
      case 'EmployeeCertificate':
        return true
    }
    return false
  }, [certificate])

  const { sendMissingCertificateReminder, rejectCertificate } = useActions()

  // send reminder
  const [
    isSendingMissingCertificateReminder,
    setIsSendingMissingCertificateReminder
  ] = useState(false)

  const [
    isMissingCertificateReminderSent,
    setIsMissingCertificateReminderSent
  ] = useState(false)

  const { modal } = App.useApp()

  const showSendMissingCertificateReminderConfirmationDialog = useCallback(
    () =>
      modal.confirm({
        title: t(
          'screens.employee_details.tabs.certificates.table.columns.actions.send_missing_certificate_reminder_confirmation_dialog.title'
        ),
        content: t(
          'screens.employee_details.tabs.certificates.table.columns.actions.send_missing_certificate_reminder_confirmation_dialog.content'
        ),
        onOk: async () => {
          try {
            setIsSendingMissingCertificateReminder(true)
            const isSent = await sendMissingCertificateReminder(
              certificate.certificateType
            )
            setIsMissingCertificateReminderSent(isSent)
          } finally {
            setIsSendingMissingCertificateReminder(false)
          }
        }
      }),
    [certificate.certificateType, modal, sendMissingCertificateReminder, t]
  )

  const handleSendMissingCertificateReminderClick = useCallback(
    async (e: MouseEvent<unknown>) => {
      e.preventDefault()
      showSendMissingCertificateReminderConfirmationDialog()
    },
    [showSendMissingCertificateReminderConfirmationDialog]
  )

  // reject certificate
  const [isRejectingCertificate, setIsRejectingCertificate] = useState(false)

  const showRejectCertificateConfirmationDialog = useCallback(
    () =>
      modal.confirm({
        title: t(
          'screens.employee_details.tabs.certificates.table.columns.actions.reject_certificate_confirmation_dialog.title'
        ),
        content: t(
          'screens.employee_details.tabs.certificates.table.columns.actions.reject_certificate_confirmation_dialog.content'
        ),
        okText: t(
          'screens.employee_details.tabs.certificates.table.columns.actions.reject_certificate_confirmation_dialog.ok_button'
        ),
        okButtonProps: {
          danger: true
        },
        cancelText: t(
          'screens.employee_details.tabs.certificates.table.columns.actions.reject_certificate_confirmation_dialog.cancel_button'
        ),
        onOk: async () => {
          try {
            setIsRejectingCertificate(true)
            await rejectCertificate(certificate.id)
          } finally {
            setIsRejectingCertificate(false)
          }
        }
      }),
    [certificate.id, modal, rejectCertificate, t]
  )

  const handleRejectCertificateClick = useCallback(
    async (e: MouseEvent<unknown>) => {
      e.preventDefault()
      showRejectCertificateConfirmationDialog()
    },
    [showRejectCertificateConfirmationDialog]
  )

  const showRejectCertificateButton = isActiveCertificate

  // after sending a reminder, we hide this button
  const showSendMissingCertificateReminderButton =
    !isActiveCertificate && !isMissingCertificateReminderSent

  const showSnoozeMissingCertificateButton = !isActiveCertificate

  return (
    <Space size="large">
      {showRejectCertificateButton ? (
        <Button
          onClick={handleRejectCertificateClick}
          loading={isRejectingCertificate}
          icon={<PaperFail />}
          type="link"
          size="middle"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          danger
        >
          {t(
            'screens.employee_details.tabs.certificates.table.columns.actions.reject_certificate_button'
          )}
        </Button>
      ) : null}

      {showSnoozeMissingCertificateButton ? (
        <Button
          to={`/employee/${employee?.id}/snooze-missing-certificate/${certificate.certificateType}`}
          openInSideSheet
          goBackTo={goBackTo}
          type="link"
          size="middle"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          icon={<ClockCircleOutlined />}
        >
          {t(
            'screens.employee_details.tabs.certificates.table.columns.actions.snooze_missing_certificate_button'
          )}
        </Button>
      ) : null}

      {showSendMissingCertificateReminderButton ? (
        <Button
          onClick={handleSendMissingCertificateReminderClick}
          loading={isSendingMissingCertificateReminder}
          icon={<Notification />}
          type="link"
          size="middle"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {t(
            'screens.employee_details.tabs.certificates.table.columns.actions.send_missing_certificate_reminder_button'
          )}
        </Button>
      ) : null}
    </Space>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@vivaldis/web-ui'
import { useTheme } from '@vivaldis/ui'
import styled from '@emotion/styled'

const Base = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
`

export const ErrorState: FC = () => {
  const [t] = useTranslation()
  const theme = useTheme()
  return (
    <Base>
      <Typography.Body color={theme.gray7} as="p" textAlign="center">
        {t('screens.employee_details.error_state.title')}
      </Typography.Body>
    </Base>
  )
}

import { gql } from '@apollo/client'

export const employeesSidebarEmployeeFragment = gql`
  fragment EmployeesSidebarEmployeeFragment on Employee {
    id
    firstName
    lastName
    profile {
      city
    }
  }
`

import { Table, InfiniteTable } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions as UnansweredSafetyQuestions } from '../../graphql/__generated__/UnansweredSafetyQuestionsTabQuery'
import {
  useUnansweredSafetyQuestionsTabQuery,
  useUnansweredSafetyQuestions
} from '../../providers/UnansweredSafetyQuestionsTabQueryProvider'
import { Position } from './components/Position'
import { Status } from './components/Status'
import { Actions } from './components/Actions'

export const UnansweredSafetyQuestionsList: FC = () => {
  const [t] = useTranslation()
  const { loading: isLoading, error } = useUnansweredSafetyQuestionsTabQuery()

  const unansweredSafetyQuestions = useUnansweredSafetyQuestions()

  const showLoading = useMemo(
    () => isLoading && !unansweredSafetyQuestions.length,
    [unansweredSafetyQuestions.length, isLoading]
  )

  return (
    <InfiniteTable<UnansweredSafetyQuestions>
      dataSource={unansweredSafetyQuestions}
      rowKey="id"
      loading={showLoading}
      isLoadingMore={false}
      onLoadMore={() => {}}
      hasNextPage={false}
      error={error}
    >
      <Table.Column<UnansweredSafetyQuestions>
        title={t(
          'screens.employee_details.tabs.unanswered_safety_questions.table.columns.position.title'
        )}
        key="position"
        render={(value, unansweredSafetyQuestion) => (
          <Position unansweredSafetyQuestion={unansweredSafetyQuestion} />
        )}
      />
      <Table.Column<UnansweredSafetyQuestions>
        title={t(
          'screens.employee_details.tabs.unanswered_safety_questions.table.columns.status.title'
        )}
        key="status"
        render={() => <Status />}
      />
      <Table.Column<UnansweredSafetyQuestions>
        title={t(
          'screens.employee_details.tabs.unanswered_safety_questions.table.columns.actions.title'
        )}
        key="actions"
        align="right"
        width={50}
        render={(value, unansweredSafetyQuestion) => (
          <Actions unansweredSafetyQuestion={unansweredSafetyQuestion} />
        )}
      />
    </InfiniteTable>
  )
}

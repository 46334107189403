import { axios } from '@vivaldis/common'

interface Params {
  email: string
  password: string
}

interface ResponseData {
  qr_code: string
}

export const registerOtpRequest = ({ email, password }: Params) => {
  return axios().post<ResponseData>(`/users/otp_register`, {
    user: {
      email: email,
      password: password
    }
  })
}

import { isAxiosError, UserType } from '@vivaldis/common'
import { useSignIn, useVerifyOTP } from '@vivaldis/session'
import { Formik, FormikContextType, useFormikContext } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

const TWO_FA_REQUIRED_ERROR = '2FA not enabled'

export interface LoginFormValues {
  email: string
  password: string
  otp_verification_code: string
}

export function useForm(): FormikContextType<LoginFormValues> {
  return useFormikContext<LoginFormValues>()!
}

interface Props {}

export const FormProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const signIn = useSignIn()
  const verifyOTP = useVerifyOTP()

  const initialValues: LoginFormValues = useMemo(
    () => ({
      email: '',
      password: '',
      otp_verification_code: ''
    }),
    []
  )

  const ValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .label(t('screens.login.form.email.label'))
          .email(t('screens.login.form.email.invalid'))
          .required(),
        password: Yup.string()
          .label(t('screens.login.form.password.label'))
          .required()
      }),
    [t]
  )

  const handleSubmit = useCallback(
    async (
      values: LoginFormValues,
      formikHelpers: FormikHelpers<LoginFormValues>
    ) => {
      // when 2FA is enabled, we need to verify the OTP code
      if (
        signIn.data &&
        'otp_verification_required' in signIn.data &&
        !!signIn.data.otp_verification_required &&
        !!signIn.data.otp_verification_token
      ) {
        verifyOTP.verifyOTP(
          {
            otp_verification_code: values.otp_verification_code,
            otp_verification_token: String(signIn.data?.otp_verification_token),
            email: values.email,
            type: UserType.User
          },
          () => {},
          error => {
            formikHelpers.setFieldError(
              'otp_verification_code',
              t(
                'screens.login.form.otp_verification_code.errors.wrong_or_invalid'
              )
            )
          }
        )
        return
      }

      signIn.signIn(
        {
          ...values,
          type: UserType.User
        },
        () => {},
        error => {
          if (
            isAxiosError(error) &&
            error?.response?.data?.error === TWO_FA_REQUIRED_ERROR
          ) {
            return navigate('/register-otp', {
              replace: true,
              state: {
                email: values.email,
                password: values.password
              }
            })
          }

          formikHelpers.setFieldError(
            'password',
            t('screens.login.form.failure')
          )
        }
      )
    },
    [navigate, signIn, t, verifyOTP]
  )

  return (
    <Formik<LoginFormValues>
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
      children={() => <>{children}</>}
    />
  )
}

import { gql } from '@apollo/client'

export const unansweredUnansweredSafetyQuestionsTabQuery = gql`
  query UnansweredSafetyQuestionsTabQuery($employeeId: ID!) {
    unansweredSafetyQuestions(employeeId: $employeeId) {
      contractDatum {
        id
        safetyQuestion
      }
      id
      name
    }
  }
`

import { Component } from 'react'
import DevSettings from './DevSettings'

interface DevProps {}

interface State {
  open: boolean
}

class DevTools extends Component<DevProps, State> {
  public state: State = {
    open: false
  }

  public _prevDevSettings = DevSettings.getInitialValue()

  public componentDidMount() {
    DevSettings.addEventListener('change', this.handleDevSettingsChange)
    const value = DevSettings.getValue()
    this._prevDevSettings = value
  }

  public handleDevSettingsChange = ({ value }: any) => {
    this._prevDevSettings = value
  }

  public toggleDevToolsMenu = () => {
    this.setState(state => ({
      open: !state.open
    }))
  }

  public renderDevTools() {
    const devSettings = DevSettings.getValue()
    const buttons = [
      'Enable Playground',
      'Enable Redux Logger',
      'Enable HTTP Logger',
      'Enable Apollo Logger',
      'Enable Tracking Logger',
      'Enable All Loggers',
      'Cancel'
    ]

    if (devSettings.playgroundEnabled) buttons[0] = 'Disable Playground 🔴'
    if (devSettings.reduxLoggerEnabled) buttons[1] = 'Disable Redux Logger 🔴'
    if (devSettings.apolloLoggerEnabled) buttons[2] = 'Disable Apollo Logger 🔴'
    if (devSettings.trackingLoggerEnabled) {
      buttons[3] = 'Disable Tracking Logger 🔴'
    }
    if (
      devSettings.reduxLoggerEnabled ||
      devSettings.apolloLoggerEnabled ||
      devSettings.trackingLoggerEnabled
    ) {
      buttons[4] = 'Disable All Logger 🔴'
    }

    const handler = (buttonIndex: number) => {
      switch (buttonIndex) {
        case 0:
          DevSettings.setValue({
            playgroundEnabled: !devSettings.playgroundEnabled
          })
          break
        case 1:
          DevSettings.setValue({
            reduxLoggerEnabled: !devSettings.reduxLoggerEnabled
          })
          break
        case 2:
          DevSettings.setValue({
            apolloLoggerEnabled: !devSettings.apolloLoggerEnabled
          })
          break
        case 3:
          DevSettings.setValue({
            trackingLoggerEnabled: !devSettings.trackingLoggerEnabled
          })
          break
        case 4: {
          const nextValue = !(
            devSettings.reduxLoggerEnabled ||
            devSettings.apolloLoggerEnabled ||
            devSettings.trackingLoggerEnabled
          )

          DevSettings.setValue({
            apolloLoggerEnabled: nextValue,
            reduxLoggerEnabled: nextValue,
            trackingLoggerEnabled: nextValue
          })
          break
        }
      }
      this.setState({ open: false })
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {buttons.map((text, index) => (
          <button
            onClick={() => handler(index)}
            key={text}
            style={{ padding: 5 }}
          >
            {text}
          </button>
        ))}
      </div>
    )
  }

  public render() {
    const { open } = this.state
    return (
      <div
        style={{
          alignItems: 'flex-end',
          backgroundColor: 'transparent',
          bottom: 50,
          display: 'flex',
          flexDirection: 'column',
          position: 'fixed',
          right: 5
        }}
      >
        {open ? this.renderDevTools() : null}
        <button
          style={{
            alignItems: 'center',
            backgroundColor: 'transparent',
            justifyContent: 'center',
            width: 40
          }}
          onClick={this.toggleDevToolsMenu}
        >
          🛠
        </button>
      </div>
    )
  }
}

export default DevTools

import { QueryResult } from '@apollo/client'
import { useQuery as useQueryBase } from '@apollo/client/react/hooks/useQuery'
import { format } from 'date-fns'
import {
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  useMemo
} from 'react'
import {
  SuEmployeesWithMissingCertificatesSidebarQuery,
  SuEmployeesWithMissingCertificatesSidebarQuery_suEmployeesWithMissingCertificates,
  SuEmployeesWithMissingCertificatesSidebarQueryVariables
} from '../graphql/__generated__/SuEmployeesWithMissingCertificatesSidebarQuery'
import { suEmployeesWithMissingCertificatesSidebarQuery } from '../graphql/suEmployeesWithMissingCertificatesSidebarQuery'
import { useForm } from './FormProvider'

export interface SuEmployeesWithMissingCertificatesQueryContextValue
  extends QueryResult<
    SuEmployeesWithMissingCertificatesSidebarQuery,
    SuEmployeesWithMissingCertificatesSidebarQueryVariables
  > {}

export const QueryContext = createContext<
  SuEmployeesWithMissingCertificatesQueryContextValue | undefined
>(undefined)

export function useSuEmployeesWithMissingCertificatesQuery(): SuEmployeesWithMissingCertificatesQueryContextValue {
  return useContext(QueryContext)!
}

export function useSuEmployeesWithMissingCertificates(): SuEmployeesWithMissingCertificatesSidebarQuery_suEmployeesWithMissingCertificates[] {
  const { data } = useSuEmployeesWithMissingCertificatesQuery()

  return useMemo(() => {
    return data?.suEmployeesWithMissingCertificates || []
  }, [data?.suEmployeesWithMissingCertificates])
}

export const SuEmployeesWithMissingCertificatesQueryProvider: FC<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const { values } = useForm()
  const variables = useMemo(() => {
    const {
      range: [periodStart, periodEnd]
    } = values
    return {
      periodStart: format(periodStart, 'yyyy-MM-dd'),
      periodEnd: format(periodEnd, 'yyyy-MM-dd')
    }
  }, [values])

  const queryResult = useQueryBase<
    SuEmployeesWithMissingCertificatesSidebarQuery,
    SuEmployeesWithMissingCertificatesSidebarQueryVariables
  >(suEmployeesWithMissingCertificatesSidebarQuery, {
    variables
  })

  return (
    <QueryContext.Provider value={queryResult}>
      {children}
    </QueryContext.Provider>
  )
}

import { Navigate, Route, Routes as RoutesBase } from 'react-router-dom'
import { FC, Suspense } from 'react'
import { useHasSession } from '@vivaldis/session'
import { ProgressBarSuspenseFallback } from '@vivaldis/web-ui'
import Login from './Auth/screens/Login'
import RegisterOTP from './Auth/screens/RegisterOTP'
import NotFound from './NotFound'
import { CompaniesRoutes } from './Companies/routes'
import { EmployeeRoutes } from './Employee/routes'
import { EmployeesRoutes } from './Employees/routes'
import { ContractsRoutes } from './Contracts/routes'
import { UsersRoutes } from './Users/routes'

export const Routes: FC = () => {
  const hasSession = useHasSession()

  if (!hasSession) {
    return (
      <Suspense fallback={ProgressBarSuspenseFallback}>
        <RoutesBase>
          <Route path="/login" element={<Login />} />
          <Route path="/register-otp" element={<RegisterOTP />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </RoutesBase>
      </Suspense>
    )
  }

  return (
    <Suspense fallback={ProgressBarSuspenseFallback}>
      <RoutesBase>
        {/* Redirects */}
        <Route path="/" element={<Navigate to="/companies" replace />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/register-otp" element={<Navigate to="/" replace />} />
        {/* Routes */}
        <Route path="/companies/*" element={<CompaniesRoutes />} />
        <Route path="/contracts/*" element={<ContractsRoutes />} />
        <Route path="/employees/*" element={<EmployeesRoutes />} />
        <Route path="/employee/*" element={<EmployeeRoutes />} />
        <Route path="/users/*" element={<UsersRoutes />} />

        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </RoutesBase>
    </Suspense>
  )
}

import { AntdConfigProvider, InfiniteList } from '@vivaldis/antd-ui'
import { FC, useMemo } from 'react'
import type { UseInfiniteScrollHookRefCallback } from 'react-infinite-scroll-hook'
import { EmployeesSidebarEmployeeFragment as Employee } from '../../../../graphql/__generated__/EmployeesSidebarEmployeeFragment'
import { useQuery, useEmployees } from '../../../../providers/QueryProvider'
import { ListItem } from './components/ListItem'

interface Props {
  sentryRef: UseInfiniteScrollHookRefCallback
  selectedEmployeeId?: string
}

export const EmployeeList: FC<Props> = ({ sentryRef, selectedEmployeeId }) => {
  const { isLoadingMore, loading, loadMore, hasNextPage, error } = useQuery()
  const employees = useEmployees()

  const showLoading = useMemo(
    () => loading && !employees.length,
    [loading, employees.length]
  )

  return (
    <AntdConfigProvider
      theme={{
        components: {
          List: {
            paddingContentVertical: 4,
            paddingContentVerticalLG: 4,
            paddingContentHorizontalLG: 0
          }
        }
      }}
    >
      <InfiniteList<Employee>
        rowKey="id"
        dataSource={employees}
        loading={showLoading}
        sentryRef={sentryRef}
        isLoadingMore={isLoadingMore}
        onLoadMore={loadMore}
        hasNextPage={hasNextPage}
        error={error}
        renderItem={employee => (
          <ListItem
            key={employee.id}
            employee={employee}
            selected={employee.id === selectedEmployeeId}
          />
        )}
        // locale={
        //   searchEnabled
        //     ? {
        //         emptyText: t('screens.staff_list.table.empty_search_text')
        //       }
        //     : undefined
        // }
      />
    </AntdConfigProvider>
  )
}

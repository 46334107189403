import Icon from '@ant-design/icons'
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon'
import { FC, forwardRef } from 'react'
import { AntdCustomIconComponentProps } from '../typings/AntdCustomIconComponentProps'

const PaperFailSvg: FC<AntdCustomIconComponentProps> = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7288 7.48437L14.7239 4.35305V5.65937C14.7239 6.66337 15.5399 7.48137 16.5429 7.48437H17.7288ZM13.8432 2.01172H7.47851C5.00851 2.01172 2.99951 4.02172 2.99951 6.49072V17.3437C3.00651 18.5597 3.48651 19.6997 4.35051 20.5537C5.20751 21.4017 6.34251 21.8667 7.54251 21.8667H7.57151L15.5805 21.8657C18.0505 21.8397 20.0565 19.8087 20.0515 17.3397V8.03972C20.0515 7.84672 19.9765 7.65972 19.8425 7.52072L14.7775 2.24172C14.6355 2.09472 14.4405 2.01172 14.2365 2.01172H14.1045C14.0621 2.00426 14.0184 2.00037 13.9739 2.00037C13.9293 2.00037 13.8857 2.00426 13.8432 2.01172ZM13.2239 3.51172V5.65937C13.2239 7.48737 14.7109 8.97937 16.5409 8.98437H18.5515V17.3407C18.5545 18.9907 17.2145 20.3477 15.5725 20.3657H7.68951L7.56651 20.3667C6.78051 20.3867 5.98351 20.0587 5.40551 19.4877C4.82651 18.9147 4.50451 18.1507 4.49951 17.3397V6.49072C4.49951 4.84872 5.83551 3.51172 7.47851 3.51172H13.2239ZM13.0752 15.3985C12.8832 15.3985 12.6912 15.3255 12.5452 15.1785L11.3428 13.976L10.1403 15.1785C9.99435 15.3255 9.80235 15.3985 9.61035 15.3985C9.41835 15.3985 9.22635 15.3255 9.08035 15.1785C8.78735 14.8855 8.78735 14.4115 9.08035 14.1185L10.2828 12.916L9.07925 11.7125C8.78625 11.4195 8.78625 10.9455 9.07925 10.6525C9.37225 10.3595 9.84625 10.3595 10.1392 10.6525L11.3428 11.856L12.5463 10.6525C12.8393 10.3595 13.3133 10.3595 13.6063 10.6525C13.8993 10.9455 13.8993 11.4195 13.6063 11.7125L12.4028 12.916L13.6052 14.1185C13.8982 14.4115 13.8982 14.8855 13.6052 15.1785C13.4592 15.3255 13.2672 15.3985 13.0752 15.3985Z"
    />
  </svg>
)

export const PaperFail = forwardRef<HTMLSpanElement, AntdIconProps>(
  (props, ref) => <Icon component={PaperFailSvg} {...props} ref={ref} />
)

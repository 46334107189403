import { Form } from '@vivaldis/antd-ui'
import { useSignIn, useVerifyOTP } from '@vivaldis/session'
import { SubmitButton } from '@vivaldis/web-ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FormContent } from './FormContent'

export const LoginForm: FC = () => {
  const [t] = useTranslation()

  const signIn = useSignIn()
  const verifyOTP = useVerifyOTP()

  return (
    <Form style={{ width: '100%' }} id="cy-su-login-form" name="su-login-form">
      <>
        <FormContent signIn={signIn} />
        <SubmitButton
          block
          marginBottom={24}
          isSubmitting={signIn.submitting || verifyOTP.submitting}
          label={t('screens.login.form.submit')}
        />
      </>
    </Form>
  )
}

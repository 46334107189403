import { TextStylePreset, Typography } from '@vivaldis/antd-ui'
import { FC } from 'react'
import { PayslipsTabPayslipsQuery_payslips as Payslip } from '../../../graphql/__generated__/PayslipsTabPayslipsQuery'

interface Props {
  payslip: Payslip
}

export const Key: FC<Props> = ({ payslip }) => {
  return (
    <Typography.Text preset={TextStylePreset.Regular14}>
      {payslip.key}
    </Typography.Text>
  )
}

import '@vivaldis/web-ui/src/components/Polyfills'
import { FC, useMemo } from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary, bugsnagClient } from '@vivaldis/web-bugsnag'
import { initBugsnagTracking } from '@vivaldis/tracking'
import { UnknownErrorPage, initFocusHandler } from '@vivaldis/web-ui'
import './i18n'
import 'antd/dist/reset.css'
import './index.css'
import './config/axios'
import { App as AntdApp, ConfigProvider } from '@vivaldis/antd-ui'
import { App as AppContainer } from '@vivaldis/app'
import storage from 'localforage'
import { UserType } from '@vivaldis/session'
import { ProgressBarProvider, SideSheets } from '@vivaldis/web-ui'
import { createApolloClient } from './config/apolloClient'
import { createReduxStore } from './config/redux'
import { reducers } from './reducers'
import { sagas } from './sagas'
import { Routes } from './screens/routes'
import { App as AppContent } from './app'
import { ThemeProvider } from './providers/ThemeProvider'

initBugsnagTracking(bugsnagClient)
initFocusHandler()

const App: FC = () => {
  const { apolloClient, apolloCachePersistor, store, storePersistor } =
    useMemo(() => {
      const { apolloClient, apolloCachePersistor } = createApolloClient()
      const { store, persistor: storePersistor } = createReduxStore(
        reducers,
        sagas
      )
      return {
        apolloClient,
        apolloCachePersistor,
        store,
        storePersistor
      }
    }, [])

  return (
    <ThemeProvider>
      <ConfigProvider>
        <AntdApp>
          <ProgressBarProvider>
            <AppContainer
              apolloClient={apolloClient}
              apolloCachePersistor={apolloCachePersistor}
              reduxStore={store}
              reduxPersistor={storePersistor}
              storage={storage}
              version={`${process.env.REACT_APP_VERSION}`}
              migrateBefore={async () => {}}
              migrateAfter={async () => {}}
              userType={UserType.SuperUser}
              actionCableURL={process.env.REACT_APP_WEB_SOCKET_URL!}
              sideSheets={
                <SideSheets>
                  <Routes />
                </SideSheets>
              }
              Providers={children => <>{children}</>}
            >
              <AppContent />
            </AppContainer>
          </ProgressBarProvider>
        </AntdApp>
      </ConfigProvider>
    </ThemeProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <ErrorBoundary FallbackComponent={UnknownErrorPage}>
    <App />
  </ErrorBoundary>
)

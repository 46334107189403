import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Result } from '@vivaldis/antd-ui'
import { Screen } from '@vivaldis/web-ui'
import { NavigationBar } from './components/NavigationBar'

const NotFound: FC = () => {
  const [t] = useTranslation()

  return (
    <Screen.EmptyState
      title={t('screens.not_found.title')}
      navigationBar={<NavigationBar />}
      EmptyStateComponent={
        <Result
          status="404"
          title="404"
          subTitle={t('screens.not_found.sub_title')}
          extra={
            <Button to="/" replace type="primary">
              {t('screens.not_found.home_button')}
            </Button>
          }
        />
      }
    />
  )
}

export default NotFound

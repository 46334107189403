import { gql } from '@apollo/client'
import { getApolloClient } from '@vivaldis/common'
import { SuMissingCertificateReminderInput } from '../../../../../typings/__generated__'
import {
  SuMissingCertificateReminder as Mutation,
  SuMissingCertificateReminderVariables as Variables
} from './__generated__/SuMissingCertificateReminder'

const suMissingCertificateReminderMutation = gql`
  mutation SuMissingCertificateReminder(
    $input: SuMissingCertificateReminderInput!
  ) {
    suMissingCertificateReminder(input: $input) {
      status
    }
  }
`

export const suMissingCertificateReminder = (
  input: SuMissingCertificateReminderInput
) => {
  const apolloClient = getApolloClient()
  return apolloClient.mutate<Mutation, Variables>({
    mutation: suMissingCertificateReminderMutation,
    variables: {
      input: input
    }
  })
}

import { Typography } from '@vivaldis/antd-ui'
import { PaperDownload } from '@vivaldis/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PayslipsTabPayslipsQuery_payslips as Payslip } from '../../../graphql/__generated__/PayslipsTabPayslipsQuery'

interface Props {
  payslip: Payslip
}

export const Actions: FC<Props> = ({ payslip }) => {
  const [t] = useTranslation()

  return (
    <Typography.Link
      href={payslip.pdfUrl}
      target={'_blank'}
      rel="noopener noreferrer"
      strong
      download
    >
      <PaperDownload style={{ marginRight: 8 }} />
      {t(
        'screens.employee_details.tabs.payslips.table.columns.actions.download_payslip'
      )}
    </Typography.Link>
  )
}

import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ScreenBase } from '../components/ScreenBase'
import { Header } from '../components/Header'
import { LoginForm } from './components/LoginForm'
import { FormProvider } from './providers/FormProvider'

const Login: FC = () => {
  const [t] = useTranslation()

  return (
    <ScreenBase title={t('screens.login.title')}>
      <Header>{t('screens.login.title')}</Header>
      <FormProvider>
        <LoginForm />
      </FormProvider>
    </ScreenBase>
  )
}

export default Login

import { gql } from '@apollo/client'
import { employeesSidebarEmployeeFragment } from './employeesSidebarEmployeeFragment'

export const suEmployeesWithMissingSafetyAnswersSidebarQuery = gql`
  ${employeesSidebarEmployeeFragment}

  query SuEmployeesWithMissingSafetyAnswersSidebarQuery {
    suEmployeesWithMissingSafetyAnswers {
      ...EmployeesSidebarEmployeeFragment
    }
  }
`

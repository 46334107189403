import { startOfToday, addMonths } from 'date-fns'
import {
  Formik,
  FormikContextType,
  FormikHelpers,
  useFormikContext
} from 'formik'
import { FC, PropsWithChildren, useCallback, useMemo } from 'react'

export interface FormValues {
  // [wageStart, wageEnd]
  wageRange?: [Date, Date]
  // [calculationStart, calculationEnd]
  calculationRange?: [Date, Date]
}

export function usePayslipsTabSearchFormSearchForm(): FormikContextType<FormValues> {
  return useFormikContext<FormValues>()!
}

export const PayslipsTabSearchFormProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const initialValues: FormValues = useMemo(
    () => ({
      // wageRange: [
      //   // periodStart
      //   addMonths(startOfToday(), -1),
      //   // periodEnd
      //   addMonths(startOfToday(), 1)
      // ]
      calculationRange: [
        // periodStart
        addMonths(startOfToday(), -2),
        // periodEnd
        addMonths(startOfToday(), 1)
      ]
    }),
    []
  )

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      actions.setSubmitting(false)
    },
    []
  )

  return (
    <Formik<FormValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {children}
    </Formik>
  )
}

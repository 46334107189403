import { App, Button, Space } from '@vivaldis/antd-ui'
import { Notification, Edit } from '@vivaldis/icons'
import { FC, MouseEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from '../../../../../hooks/useParams'
import { useActions } from '../../../../../providers/ActionsProvider'
import { UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions } from '../../../graphql/__generated__/UnansweredSafetyQuestionsTabQuery'

interface Props {
  unansweredSafetyQuestion: UnansweredSafetyQuestionsTabQuery_unansweredSafetyQuestions
}

export const Actions: FC<Props> = ({ unansweredSafetyQuestion }) => {
  const [t] = useTranslation()
  const { employeeId } = useParams()

  const { suMissingSafetyAnswerReminder } = useActions()

  const [
    isSendingMissingSafetyAnswerReminder,
    setIsSendingMissingSafetyAnswerReminder
  ] = useState(false)

  const [
    isMissingSafetyAnswerReminderSent,
    setIsMissingSafetyAnswerReminderSent
  ] = useState(false)

  const { modal } = App.useApp()

  const showSendMissingSafetyAnswerReminderConfirmationDialog = useCallback(
    () =>
      modal.confirm({
        title: t(
          'screens.employee_details.tabs.unanswered_safety_questions.table.columns.actions.send_missing_safety_answer_reminder_confirmation_dialog.title'
        ),
        content: t(
          'screens.employee_details.tabs.unanswered_safety_questions.table.columns.actions.send_missing_safety_answer_reminder_confirmation_dialog.content'
        ),
        onOk: async () => {
          try {
            setIsSendingMissingSafetyAnswerReminder(true)
            const isSent = await suMissingSafetyAnswerReminder(
              String(unansweredSafetyQuestion.contractDatum?.safetyQuestion)
            )
            setIsMissingSafetyAnswerReminderSent(isSent)
          } finally {
            setIsSendingMissingSafetyAnswerReminder(false)
          }
        }
      }),
    [
      modal,
      suMissingSafetyAnswerReminder,
      t,
      unansweredSafetyQuestion.contractDatum?.safetyQuestion
    ]
  )

  const handleSendMissingSafetyAnswerReminderClick = useCallback(
    async (e: MouseEvent<unknown>) => {
      e.preventDefault()
      showSendMissingSafetyAnswerReminderConfirmationDialog()
    },
    [showSendMissingSafetyAnswerReminderConfirmationDialog]
  )

  return (
    <Space size="middle">
      {isMissingSafetyAnswerReminderSent ? null : (
        <Button
          onClick={handleSendMissingSafetyAnswerReminderClick}
          loading={isSendingMissingSafetyAnswerReminder}
          icon={<Notification />}
          type="link"
          size="middle"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {t(
            'screens.employee_details.tabs.unanswered_safety_questions.table.columns.actions.send_missing_safety_answer_reminder_button'
          )}
        </Button>
      )}
      <Button
        icon={<Edit />}
        to={`/employee/${employeeId}/set-employee-safety-answer/${unansweredSafetyQuestion.id}`}
        openInSideSheet
        type="link"
        size="middle"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        {t(
          'screens.employee_details.tabs.unanswered_safety_questions.table.columns.actions.answer_button'
        )}
      </Button>
    </Space>
  )
}

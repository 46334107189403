import { FC } from 'react'
import { Avatar } from '@vivaldis/antd-ui'
import { UserOutlined } from '@ant-design/icons'
import { useUser } from '@vivaldis/session'

export const CompanyAvatar: FC = () => {
  const user = useUser()
  return (
    <Avatar
      size={38}
      icon={<UserOutlined />}
      src={user?.company?.logo}
      style={{
        position: 'absolute',
        right: 0
      }}
    />
  )
}

import { useTheme } from '@vivaldis/ui'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Tabs } from '@vivaldis/antd-ui'
import { EmployeeDetailsQuery_employee } from '../../graphql/__generated__/EmployeeDetailsQuery'
import { CertificatesTab } from '../../tabs/CertificatesTab'
import { useMissingCertificates } from '../../providers/QueryProvider'
import { PayslipsTab } from '../../tabs/PayslipsTab'
import { UnansweredSafetyQuestionsTab } from '../../tabs/UnansweredSafetyQuestionsTab'
import { TopBar } from './components/TopBar'
import { TabPaneContentBase } from './components/TabPaneContentBase'

enum TabValues {
  CERTIFICATES = 'CERTIFICATES',
  UNANSWERED_SAFETY_QUESTIONS = 'UNANSWERED_SAFETY_QUESTIONS',
  PAYSLIPS = 'PAYSLIPS'
}

interface CompanyContentProps {
  employee: EmployeeDetailsQuery_employee
}

export const EmployeeDetailsContent: FC<CompanyContentProps> = ({
  employee
}) => {
  const [t] = useTranslation()
  const theme = useTheme()

  const missingCertificatesCount = useMissingCertificates().length
  const tabsItems = useMemo(() => {
    return [
      {
        key: TabValues.CERTIFICATES,
        label: (
          <div>
            {t('screens.employee_details.tabs.certificates.title')}
            <Badge
              dot={!!missingCertificatesCount}
              color={theme.orange6}
              offset={[-1, 3]}
            >
              <div>&nbsp;</div>
            </Badge>
          </div>
        ),
        children: (
          <TabPaneContentBase>
            <CertificatesTab employee={employee} />
          </TabPaneContentBase>
        )
      },
      {
        key: TabValues.UNANSWERED_SAFETY_QUESTIONS,
        label: t(
          'screens.employee_details.tabs.unanswered_safety_questions.title'
        ),
        children: (
          <TabPaneContentBase>
            <UnansweredSafetyQuestionsTab />
          </TabPaneContentBase>
        )
      },
      {
        key: TabValues.PAYSLIPS,
        label: t('screens.employee_details.tabs.payslips.title'),
        children: (
          <TabPaneContentBase>
            <PayslipsTab />
          </TabPaneContentBase>
        )
      }
    ]
  }, [employee, missingCertificatesCount, t, theme.orange6])

  return (
    <>
      <TopBar employee={employee} />
      <Tabs
        defaultActiveKey={TabValues.CERTIFICATES}
        items={tabsItems}
        size="large"
        tabBarStyle={{
          paddingLeft: 32,
          backgroundColor: theme.white
        }}
        destroyInactiveTabPane
      />
    </>
  )
}

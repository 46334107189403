import { gql } from '@apollo/client'

export const employeeDetailsQuery = gql`
  query EmployeeDetailsQuery($employeeId: ID!) {
    employee(id: $employeeId) {
      employeeCertificates {
        certificateType
        expiresOn
        id
        scannedDocumentUrl
      }
      firstName
      gender
      lastName
      id
      mobile
      profile {
        birthdate
        buildingNumber
        country {
          id
          nameEn
          nameFr
          nameNl
          eeaMember
        }
        countryOfBirth {
          id
          eeaMember
        }
        nationalityCountry {
          id
          eeaMember
        }
        street
        streetNumber
        zip
        city
        country {
          id
        }
        idCardBackPicture
        idCardExpiresOn
        idCardFrontPicture
        idCardNumber
      }
      ssn
      workProfile {
        bankAccount
        bic
        residencePermitBackPicture
        residencePermitCode
        residencePermitExpiresOn
        residencePermitFrontPicture
        workPermitBackPicture
        workPermitExpiresOn
        workPermitFrontPicture
      }
    }
    missingCertificates(
      employeeId: $employeeId
      periodStart: "2020-01-01"
      periodEnd: "2040-01-01"
    ) {
      certificates
      positionName
    }
  }
`

import { FC, PropsWithChildren } from 'react'
import { useUpdateProgressBar } from '@vivaldis/web-ui'
import { useQuery } from './QueryProvider'

export const ProgressBarProvider: FC<PropsWithChildren<unknown>> = ({
  children
}) => {
  const { data, loading } = useQuery()

  useUpdateProgressBar(loading && !data)

  return <>{children}</>
}
